/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmailAddress: any;
  GeometryCoordinates: any;
  GraphQLDate: any;
  GraphQLDateTime: any;
  JSONObject: any;
  PhoneNumber: any;
  URL: any;
  Void: any;
};

export type AddUnitsTouredInput = {
  isTourStarted?: InputMaybe<Scalars['Boolean']>;
  tourId: Scalars['ID'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type Amenities = {
  __typename?: 'Amenities';
  carport?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
};

export type AmenitiesDetails = {
  __typename?: 'AmenitiesDetails';
  description?: Maybe<Scalars['String']>;
  disclosures: Array<AmenitiesDetailsItem>;
  html?: Maybe<Scalars['String']>;
  icons?: Maybe<Array<Icon>>;
  items: Array<AmenitiesDetailsItem>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AmenitiesDetailsItem = {
  __typename?: 'AmenitiesDetailsItem';
  anchors: Array<Anchor>;
  text?: Maybe<Scalars['String']>;
};

export type AmenityGroupType = {
  displayOrder: Scalars['String'];
  filterQueryType: Scalars['String'];
  name: Scalars['String'];
  searchFeaturedAmenity: Scalars['Boolean'];
  title: Scalars['String'];
};

export type AmenityTourTemplate = {
  __typename?: 'AmenityTourTemplate';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  mapData?: Maybe<MapData>;
  metadata?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type Anchor = {
  __typename?: 'Anchor';
  data?: Maybe<AnchorData>;
  href?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type AnchorData = {
  __typename?: 'AnchorData';
  content?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Apartment = {
  __typename?: 'Apartment';
  /** @deprecated use unitAmenities & unitFloor */
  amenities?: Maybe<Amenities>;
  /** @deprecated use unitTypeName */
  attribute?: Maybe<Scalars['String']>;
  /** @deprecated use floorplanBath */
  bathrooms?: Maybe<Scalars['Float']>;
  /** @deprecated use floorplanBed */
  bedrooms?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  buildingUnit?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Coordinates>;
  floorPlan?: Maybe<Scalars['URL']>;
  floorPlanBath?: Maybe<Scalars['Float']>;
  floorPlanBed?: Maybe<Scalars['Int']>;
  floorPlanDeposit?: Maybe<Scalars['Int']>;
  floorPlanGroup?: Maybe<Array<Scalars['String']>>;
  floorPlanName?: Maybe<Scalars['String']>;
  floorPlanSqFt?: Maybe<Scalars['Int']>;
  floorplanUniqueID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isFutureUnit?: Maybe<Scalars['Boolean']>;
  isModel?: Maybe<Scalars['Boolean']>;
  leaseUp?: Maybe<Scalars['Boolean']>;
  occupancyStatus?: Maybe<Scalars['String']>;
  oneSiteUnitId?: Maybe<Scalars['String']>;
  /** @deprecated use unitStartingPrice or unitLeasePrices */
  pricingAndAvailability?: Maybe<PricingAndAvailability>;
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['String']>;
  squareFeet?: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  type?: Maybe<Scalars['String']>;
  unitAmenities?: Maybe<Array<Scalars['String']>>;
  unitFloor?: Maybe<Scalars['Int']>;
  unitHasDen?: Maybe<Scalars['Boolean']>;
  unitHasLoft?: Maybe<Scalars['Boolean']>;
  unitId?: Maybe<Scalars['String']>;
  unitLeasingPrices?: Maybe<Array<UnitLeasingPrice>>;
  unitStartingPrice?: Maybe<UnitLeasingPrice>;
  unitTypeCode?: Maybe<Scalars['String']>;
  unitTypeName?: Maybe<Scalars['String']>;
};

export type ApartmentFloorPlanArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type ApartmentPricingAndAvailabilityArgs = {
  startDate?: InputMaybe<Scalars['GraphQLDate']>;
};

export type ApartmentUnitLeasingPricesArgs = {
  filter?: InputMaybe<UnitLeasingPriceFilter>;
};

export type ApartmentInfo = {
  buildingUnit: Scalars['String'];
  communityId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type ApartmentsFilter = {
  __typename?: 'ApartmentsFilter';
  bathrooms?: Maybe<Array<Scalars['Float']>>;
  bedrooms?: Maybe<Array<Scalars['String']>>;
  floor?: Maybe<Array<Scalars['Int']>>;
  priceRange?: Maybe<PriceRangeFilter>;
  sqFtRange?: Maybe<PriceRangeFilter>;
};

export type ApartmentsFilterInput = {
  amenities?: InputMaybe<Array<AmenityGroupType>>;
  bathrooms?: InputMaybe<Array<Scalars['Float']>>;
  bedrooms?: InputMaybe<Array<Scalars['String']>>;
  floor?: InputMaybe<Scalars['Int']>;
  priceRange?: InputMaybe<PriceRangeFilterInput>;
  sqFtRange?: InputMaybe<PriceRangeFilterInput>;
};

export type AppHealth = {
  __typename?: 'AppHealth';
  cpu?: Maybe<Scalars['JSONObject']>;
  environment: Scalars['String'];
  memory?: Maybe<Scalars['JSONObject']>;
  uptime: Scalars['String'];
  version: Scalars['String'];
};

export type BaseMapAdministrators = {
  __typename?: 'BaseMapAdministrators';
  emails: Array<Scalars['String']>;
};

export type BaseTemplate = {
  __typename?: 'BaseTemplate';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  isModel?: Maybe<Scalars['Boolean']>;
  mapData?: Maybe<MapData>;
  metadata?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type BeginTourInput = {
  tourId: Scalars['String'];
};

export type BlockMapInput = {
  shouldBlockMap: Scalars['Boolean'];
  tourId: Scalars['String'];
};

export enum BookingStatus {
  Applied = 'APPLIED',
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
  Failed = 'FAILED',
  Leased = 'LEASED',
  NoShow = 'NO_SHOW',
  Rescheduled = 'RESCHEDULED',
  Toured = 'TOURED',
  Unspecified = 'UNSPECIFIED',
}

export type BrivoDigitalInvitation = {
  __typename?: 'BrivoDigitalInvitation';
  accessCode?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['GraphQLDateTime']>;
  expiration?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['Int']>;
  referenceId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CachedPricing = {
  __typename?: 'CachedPricing';
  leaseTerm?: Maybe<Scalars['Int']>;
  rentAmount?: Maybe<Scalars['Int']>;
};

export type CancelTourInput = {
  tourId: Scalars['ID'];
};

export type CancelTourPayload = {
  __typename?: 'CancelTourPayload';
  cancelledTour?: Maybe<CancelledTour>;
};

export type CancelledTour = {
  __typename?: 'CancelledTour';
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  prospectEmail?: Maybe<Scalars['String']>;
  tourId?: Maybe<Scalars['String']>;
  tourMap?: Maybe<TourMap>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type Category = {
  __typename?: 'Category';
  title?: Maybe<Scalars['String']>;
};

export type City = {
  __typename?: 'City';
  communities?: Maybe<Array<Community>>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
};

export type Community = {
  __typename?: 'Community';
  address?: Maybe<Address>;
  amenities?: Maybe<Array<Scalars['String']>>;
  amenitiesDetails?: Maybe<AmenitiesDetails>;
  amenitiesImage?: Maybe<Scalars['URL']>;
  apartmentsFilter?: Maybe<ApartmentsFilter>;
  availableCount?: Maybe<Scalars['Int']>;
  city?: Maybe<City>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Scalars['String']>>;
  featuresDetails?: Maybe<AmenitiesDetails>;
  featuresImage?: Maybe<Scalars['URL']>;
  floorPlanOptions?: Maybe<Array<TourFloorPlan>>;
  floorPlans?: Maybe<Array<Maybe<FloorPlans>>>;
  hideApplyNow?: Maybe<Scalars['Boolean']>;
  icons?: Maybe<Array<Icon>>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['URL']>;
  isBrivo?: Maybe<Scalars['Boolean']>;
  leasingHours?: Maybe<Array<Array<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Property>>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  region?: Maybe<Region>;
  scheduleTourInfo?: Maybe<ScheduleTourInfo>;
  /** @deprecated Updated to fill out tour within the app */
  scheduleTourLink?: Maybe<Scalars['String']>;
  zingleNumber?: Maybe<Scalars['String']>;
};

export type CommunityAmenitiesImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CommunityAvailableCountArgs = {
  filter?: InputMaybe<ApartmentsFilterInput>;
};

export type CommunityFeaturesImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CommunityFloorPlansArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CommunityImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CommunityPasscode = {
  __typename?: 'CommunityPasscode';
  agentId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  enableForPreArrival?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  passcode?: Maybe<Scalars['String']>;
};

export type CommunityTile = {
  __typename?: 'CommunityTile';
  amenityKeylessProvider?: Maybe<Scalars['String']>;
  bearing?: Maybe<Scalars['Float']>;
  bounds?: Maybe<Scalars['JSONObject']>;
  community?: Maybe<Community>;
  communityId?: Maybe<Scalars['ID']>;
  communityName?: Maybe<Scalars['String']>;
  enablePreArrivalInstructionLockDown?: Maybe<Scalars['Boolean']>;
  hasAutoMarkerPlacement?: Maybe<Scalars['Boolean']>;
  /** @deprecated use amenityKeylessProvider */
  hasKeylessAmenitiesOnly?: Maybe<Scalars['Boolean']>;
  hideFloors?: Maybe<Scalars['Boolean']>;
  isKeyless?: Maybe<Scalars['Boolean']>;
  lastEdited?: Maybe<Scalars['GraphQLDateTime']>;
  mapBoxId?: Maybe<Scalars['String']>;
  mapsIndoorsApi?: Maybe<Scalars['String']>;
  mapsIndoorsVenueId?: Maybe<Scalars['String']>;
  mapsPeopleEnabled?: Maybe<Scalars['Boolean']>;
  maximumTravelDistance?: Maybe<Scalars['Int']>;
  preArrivalInstructions?: Maybe<Scalars['String']>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  requiredAmenities?: Maybe<Array<Scalars['String']>>;
  shouldShowMapsPeopleExperiencePopUp?: Maybe<Scalars['Boolean']>;
  unitKeylessProvider?: Maybe<Scalars['String']>;
};

export type CommunityTileLastEditedArgs = {
  isModel?: InputMaybe<Scalars['Boolean']>;
};

export type CommunityUnitLabels = {
  __typename?: 'CommunityUnitLabels';
  floors?: Maybe<Array<Scalars['Int']>>;
  labels?: Maybe<Array<Scalars['JSONObject']>>;
};

export type CompleteTourMapInput = {
  notes?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idVerificationDateTime?: Maybe<Scalars['GraphQLDateTime']>;
  idVerificationExpiration?: Maybe<Scalars['GraphQLDateTime']>;
  idVerificationMessage?: Maybe<Scalars['String']>;
  idVerificationStatus?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  address?: Maybe<Address>;
  leasingEmailAddress?: Maybe<Scalars['String']>;
  leasingPhoneNumber?: Maybe<Scalars['String']>;
  tourDayPhoneNumber?: Maybe<Scalars['String']>;
};

export type ContentfulData = {
  __typename?: 'ContentfulData';
  amenityKeylessProvider?: Maybe<Scalars['String']>;
  hasChat?: Maybe<Scalars['Boolean']>;
  /** @deprecated use unitKeylessProvider and/or amenityKeylessProvider */
  isBrivo?: Maybe<Scalars['Boolean']>;
  isKeyless?: Maybe<Scalars['Boolean']>;
  unitKeylessProvider?: Maybe<Scalars['String']>;
  zingleNumber?: Maybe<Scalars['String']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type CreateFeedbackInput = {
  buildingUnit: Scalars['String'];
  comments?: InputMaybe<Scalars['String']>;
  like?: InputMaybe<Scalars['Boolean']>;
  tourId: Scalars['String'];
  want?: InputMaybe<Scalars['Boolean']>;
};

export type CreateKeylessEntriesInput = {
  tourId: Scalars['String'];
};

export type CreateKeylessEntriesPayload = {
  __typename?: 'CreateKeylessEntriesPayload';
  isReady: Scalars['Boolean'];
  keylessEntries: Array<KeylessEntry>;
};

export type CreateKeylessExceptionInput = {
  apartmentId: Scalars['String'];
  communityId: Scalars['String'];
  metadata: KeylessExceptionMetadataInput;
  propertyId: Scalars['String'];
};

export type CreateTestTourInput = {
  communityId: Scalars['ID'];
  firstName: Scalars['String'];
  ignoreRestrictions?: InputMaybe<Scalars['Boolean']>;
  isModel?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  propertyId: Scalars['ID'];
  prospectEmail?: InputMaybe<Scalars['String']>;
  prospectPhoneNumber: Scalars['String'];
  skipIdVerification?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['GraphQLDateTime'];
};

export type CreateTestTourPayload = {
  __typename?: 'CreateTestTourPayload';
  tour?: Maybe<Tour>;
};

export type DeletePreArrivalMessageInput = {
  id: Scalars['ID'];
};

export type DeletePreArrivalMessagePayload = {
  __typename?: 'DeletePreArrivalMessagePayload';
  preArrivalMessage?: Maybe<PreArrivalMessage>;
};

export type DeleteTourMapInput = {
  tourId: Scalars['String'];
};

export type DisplayRule = {
  __typename?: 'DisplayRule';
  clickable?: Maybe<Scalars['Boolean']>;
  extrusionColor?: Maybe<Scalars['String']>;
  extrusionHeight?: Maybe<Scalars['Int']>;
  extrusionVisible?: Maybe<Scalars['Boolean']>;
  extrusionZoomFrom?: Maybe<Scalars['Int']>;
  extrusionZoomTo?: Maybe<Scalars['Int']>;
  icon?: Maybe<Scalars['String']>;
  iconScale?: Maybe<Scalars['Int']>;
  iconSize?: Maybe<Size>;
  iconVisible?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  labelMaxWidth?: Maybe<Scalars['Boolean']>;
  labelVisible?: Maybe<Scalars['Boolean']>;
  labelZoomFrom?: Maybe<Scalars['Int']>;
  labelZoonTo?: Maybe<Scalars['Int']>;
  model2DBearing?: Maybe<Scalars['Int']>;
  model2DHeightMeters?: Maybe<Scalars['Int']>;
  model2DModel?: Maybe<Scalars['String']>;
  model2DVisible?: Maybe<Scalars['Boolean']>;
  model2DWidthMeters?: Maybe<Scalars['Int']>;
  model2DZoomFrom?: Maybe<Scalars['Int']>;
  model2DZoomTo?: Maybe<Scalars['Int']>;
  model3DModel?: Maybe<Scalars['String']>;
  model3DRotationX?: Maybe<Scalars['Int']>;
  model3DRotationY?: Maybe<Scalars['Int']>;
  model3DRotationZ?: Maybe<Scalars['Int']>;
  model3DScale?: Maybe<Scalars['Int']>;
  model3DVisible?: Maybe<Scalars['Boolean']>;
  model3DZoomFrom?: Maybe<Scalars['Int']>;
  model3DZoomTo?: Maybe<Scalars['Int']>;
  polygonFillColor?: Maybe<Scalars['String']>;
  polygonFillOpacity?: Maybe<Scalars['Int']>;
  polygonStrokeColor?: Maybe<Scalars['String']>;
  polygonStrokeOpacity?: Maybe<Scalars['Int']>;
  polygonStrokeWeight?: Maybe<Scalars['Int']>;
  polygonVisible?: Maybe<Scalars['Boolean']>;
  polygonZoomFrom?: Maybe<Scalars['Int']>;
  polygonZoomTo?: Maybe<Scalars['Int']>;
  visible?: Maybe<Scalars['Boolean']>;
  wallsColor?: Maybe<Scalars['String']>;
  wallsHeight?: Maybe<Scalars['Int']>;
  wallsVisible?: Maybe<Scalars['Boolean']>;
  wallsZoomFrom?: Maybe<Scalars['Int']>;
  wallsZoomTo?: Maybe<Scalars['Int']>;
  zoomFrom?: Maybe<Scalars['Int']>;
  zoomTo?: Maybe<Scalars['Int']>;
};

export type DistancesType = {
  __typename?: 'DistancesType';
  distance?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['String']>;
  stopIndex?: Maybe<Scalars['Int']>;
};

export type ExtendTourInput = {
  notifier?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type Feature = {
  __typename?: 'Feature';
  geometry?: Maybe<FeatureGeometry>;
  id?: Maybe<Scalars['ID']>;
  properties?: Maybe<FeatureProperties>;
  type?: Maybe<Scalars['String']>;
};

export type FeatureGeometry = {
  __typename?: 'FeatureGeometry';
  coordinates?: Maybe<Scalars['GeometryCoordinates']>;
  type?: Maybe<Scalars['String']>;
};

export type FeatureProperties = MarkerProperties | RouteProperties;

export type Feedback = {
  __typename?: 'Feedback';
  buildingUnit: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id: Scalars['String'];
  like?: Maybe<Scalars['Boolean']>;
  prospectEmail: Scalars['String'];
  prospectId: Scalars['String'];
  tourId: Scalars['String'];
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
  want?: Maybe<Scalars['Boolean']>;
};

export type FiltersType = {
  availability?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildingNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  floorPlanBath?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  floorPlanBed?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  floorplanUniqueID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  handicapAccessible?: InputMaybe<Scalars['Boolean']>;
  petFriendly?: InputMaybe<Scalars['Boolean']>;
  priceRange?: InputMaybe<MinMaxInt>;
  propertyID?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sqFtRange?: InputMaybe<MinMaxInt>;
  unitFloor?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  unitHasDen?: InputMaybe<Scalars['Boolean']>;
  unitHasLoft?: InputMaybe<Scalars['Boolean']>;
};

export type FloorPlans = {
  __typename?: 'FloorPlans';
  attribute?: Maybe<Scalars['String']>;
  bedrooms?: Maybe<Scalars['String']>;
  floorPlan?: Maybe<Scalars['URL']>;
  floorPlanBath?: Maybe<Scalars['Float']>;
  floorPlanName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  squareFeet?: Maybe<Scalars['Int']>;
};

export type Icon = {
  __typename?: 'Icon';
  icon?: Maybe<Scalars['URL']>;
  name?: Maybe<Scalars['String']>;
};

export type IdVerification = {
  __typename?: 'IdVerification';
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  errorDetails?: Maybe<IdVerificationErrorDetails>;
  externalId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  idUsState?: Maybe<Scalars['String']>;
  identityVerification?: Maybe<IdentityVerification>;
  isValid?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  nameCheckFailed?: Maybe<Scalars['Boolean']>;
  prospectEmail?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<RejectReason>;
  verificationStatus?: Maybe<Scalars['String']>;
};

export type IdVerificationErrorDetails = {
  __typename?: 'IdVerificationErrorDetails';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  internalTitle?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IdVerificationLink = {
  __typename?: 'IdVerificationLink';
  href?: Maybe<Scalars['String']>;
};

export type IdVerificationSummary = {
  __typename?: 'IdVerificationSummary';
  errorDetails?: Maybe<IdVerificationErrorDetails>;
  errorProcessing?: Maybe<Scalars['Boolean']>;
  expirationDate?: Maybe<Scalars['GraphQLDateTime']>;
  externalId?: Maybe<Scalars['String']>;
  fraudDetected?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
};

export type IdVerificationToken = {
  __typename?: 'IdVerificationToken';
  token?: Maybe<Scalars['String']>;
};

export type IdVerifiedPayload = {
  __typename?: 'IdVerifiedPayload';
  errorDetails?: Maybe<IdVerificationErrorDetails>;
  expirationDate?: Maybe<Scalars['GraphQLDateTime']>;
  idVerification?: Maybe<IdVerification>;
};

export type IdentityVerification = {
  __typename?: 'IdentityVerification';
  reason?: Maybe<Scalars['String']>;
  similarity?: Maybe<IdentityVerificationSimilarity>;
  validity?: Maybe<Scalars['Boolean']>;
};

export enum IdentityVerificationSimilarity {
  Match = 'MATCH',
  NotPossible = 'NOT_POSSIBLE',
  NoMatch = 'NO_MATCH',
}

export type KeylessEngagedUnitInput = {
  apartmentId: Scalars['String'];
  tourId: Scalars['ID'];
};

export type KeylessEntries = {
  __typename?: 'KeylessEntries';
  isReady: Scalars['Boolean'];
  keylessEntries: Array<KeylessEntry>;
};

export type KeylessEntriesInput = {
  tourId: Scalars['ID'];
};

export type KeylessEntry = {
  __typename?: 'KeylessEntry';
  apartmentId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['GraphQLDateTime'];
  deleted?: Maybe<Scalars['Boolean']>;
  endTime?: Maybe<Scalars['GraphQLDateTime']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBrivo?: Maybe<Scalars['Boolean']>;
  propertyId: Scalars['ID'];
  prospectEmail?: Maybe<Scalars['String']>;
  prospectPhoneNumber?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  startTime?: Maybe<Scalars['GraphQLDateTime']>;
  tourId: Scalars['ID'];
  updatedAt: Scalars['GraphQLDateTime'];
};

export type KeylessEntryInstitution = {
  __typename?: 'KeylessEntryInstitution';
  apartmentId: Scalars['ID'];
  id: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type KeylessEntryInstitutions = {
  __typename?: 'KeylessEntryInstitutions';
  keylessEntryInstitutions: Array<KeylessEntryInstitution>;
};

export type KeylessException = {
  __typename?: 'KeylessException';
  apartmentId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  propertyId?: Maybe<Scalars['String']>;
};

export type KeylessExceptionMetadataInput = {
  bath: Scalars['Float'];
  bed: Scalars['Int'];
  floor: Scalars['Int'];
  sqFt: Scalars['Int'];
};

export enum KeylessStatus {
  Enabled = 'ENABLED',
  KeylessError = 'KEYLESS_ERROR',
  KeylessPending = 'KEYLESS_PENDING',
  NotKeyless = 'NOT_KEYLESS',
}

export type KeylessUpdateInput = {
  apartmentId: Scalars['String'];
  communityId: Scalars['String'];
  metadata: KeylessExceptionMetadataInput;
  propertyId: Scalars['String'];
};

export type LeasingAgent = {
  __typename?: 'LeasingAgent';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type LeasingAgentInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type LocationGeometry = {
  __typename?: 'LocationGeometry';
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  coordinates?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MpLocation = {
  __typename?: 'MPLocation';
  area?: Maybe<Scalars['Float']>;
  geometry?: Maybe<LocationGeometry>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Array<Maybe<Scalars['String']>>>;
  properties?: Maybe<MpProperties>;
  type?: Maybe<Scalars['String']>;
};

export type MpProperties = {
  __typename?: 'MPProperties';
  aliases?: Maybe<Array<Maybe<Scalars['String']>>>;
  anchor?: Maybe<Point>;
  building?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  description?: Maybe<Scalars['String']>;
  displayRule?: Maybe<DisplayRule>;
  externalId?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  floorName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  locationType?: Maybe<Scalars['String']>;
  mapElement?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  venueId?: Maybe<Scalars['String']>;
};

export type MapData = {
  __typename?: 'MapData';
  features?: Maybe<Array<Feature>>;
  type?: Maybe<Scalars['String']>;
};

export type MapsindoorsTourMapInput = {
  customTravelDistance?: InputMaybe<Scalars['Int']>;
  previousState?: InputMaybe<Scalars['JSONObject']>;
  refresh?: InputMaybe<Scalars['Boolean']>;
  tourId: Scalars['String'];
};

export type MapsindoorsTourMapReturnType = {
  __typename?: 'MapsindoorsTourMapReturnType';
  distances?: Maybe<Scalars['JSONObject']>;
  error?: Maybe<Scalars['String']>;
  updatedTourMap?: Maybe<TourMap>;
};

export type MarkerProperties = {
  __typename?: 'MarkerProperties';
  amenityTitle?: Maybe<Scalars['String']>;
  apartmentId?: Maybe<Scalars['String']>;
  autoNotes?: Maybe<Array<Maybe<Scalars['String']>>>;
  brivoAccessPointId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  elevatorNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  /** @deprecated this is a pivotal artifact, use floorplanUniqueID instead */
  floorplanCRMID?: Maybe<Scalars['String']>;
  floorplanName?: Maybe<Scalars['String']>;
  floorplanUniqueID?: Maybe<Scalars['String']>;
  gateNumber?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  iconBase?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isKeyless?: Maybe<Scalars['Boolean']>;
  /** @deprecated use new model marker type, rather than checkbox */
  isModel?: Maybe<Scalars['Boolean']>;
  isOnRoute?: Maybe<Scalars['Boolean']>;
  isPinEnabled?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  markerType?: Maybe<MarkerType>;
  mpLocation?: Maybe<MpLocation>;
  /** @deprecated No longer supported */
  navHelp?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onesiteId?: Maybe<Scalars['String']>;
  opacityIncreased?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  removeFromMPRoute?: Maybe<Scalars['Boolean']>;
  routeStops?: Maybe<Array<RouteStop>>;
  unitId?: Maybe<Scalars['String']>;
};

export enum MarkerType {
  AccessDoor = 'ACCESS_DOOR',
  Amenity = 'AMENITY',
  Elevator = 'ELEVATOR',
  FutureUnit = 'FUTURE_UNIT',
  Gate = 'GATE',
  General = 'GENERAL',
  LeasedUnit = 'LEASED_UNIT',
  ModelUnit = 'MODEL_UNIT',
  Note = 'NOTE',
  Parking = 'PARKING',
  Stairs = 'STAIRS',
  Start = 'START',
  Unit = 'UNIT',
  Wheelchair = 'WHEELCHAIR',
}

export type MinMaxInt = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type ModelTourTemplate = {
  __typename?: 'ModelTourTemplate';
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  mapData?: Maybe<MapData>;
  metadata?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['String']>;
  addKeylessEngagedUnit?: Maybe<Scalars['Boolean']>;
  addUnitsToured?: Maybe<Scalars['Boolean']>;
  beginTour: TourMap;
  blockMap?: Maybe<TourMap>;
  cancelTour: CancelTourPayload;
  completeTourMap?: Maybe<TourMap>;
  createFeedback?: Maybe<Scalars['Boolean']>;
  createIdVerification?: Maybe<Scalars['Boolean']>;
  createKeylessEntries: CreateKeylessEntriesPayload;
  createKeylessException: KeylessException;
  createKeylessExceptions: Array<KeylessException>;
  createTestTour: CreateTestTourPayload;
  deleteCommunityPasscode?: Maybe<Scalars['Boolean']>;
  deletePreArrivalMessage: DeletePreArrivalMessagePayload;
  deleteTourMap?: Maybe<TourMap>;
  extendTour?: Maybe<TourMap>;
  flushAllCache: Scalars['String'];
  flushDataCache: Scalars['String'];
  mapsindoorsTourMap?: Maybe<MapsindoorsTourMapReturnType>;
  removeKeylessEntries: RemoveKeylessEntriesPayload;
  removeKeylessEntry: RemoveKeylessEntryPayload;
  removeKeylessEntrySkipKastle: KeylessEntry;
  removeKeylessException: RemoveExceptionResponse;
  removeKeylessExceptionById: RemoveExceptionResponse;
  removeKeylessExceptionByIds: RemoveExceptionResponse;
  removeKeylessExceptions: RemoveExceptionResponse;
  rescheduleTour: RescheduleTourPayload;
  saveAmenityTourTemplate?: Maybe<AmenityTourTemplate>;
  saveBaseTemplate?: Maybe<BaseTemplate>;
  saveCommunityPasscode: CommunityPasscode;
  saveModelTourTemplate?: Maybe<ModelTourTemplate>;
  savePreArrivalMessage: SavePreArrivalMessagePayload;
  saveTourMap?: Maybe<TourMap>;
  saveTourNote?: Maybe<TourMap>;
  saveUnitInfo?: Maybe<Array<Maybe<TourMap>>>;
  scheduleTour: ScheduleTourPayload;
  setTourMapReady?: Maybe<TourMap>;
  setUnitVisitedTimestamp?: Maybe<TourMap>;
  /** @deprecated no longer used */
  signWaiver?: Maybe<Scalars['GraphQLDate']>;
  submitWalkInTour: SubmitWalkInTourPayload;
  unNotifyTourAndRemoveKeylessEntries: Array<Maybe<RemoveKeylessEntryPayload>>;
  updateKeylessExceptions?: Maybe<UpdateExceptionsResponse>;
  updateTourMap?: Maybe<TourMap>;
  verifyIdentity?: Maybe<IdVerificationToken>;
  verifyWebIdentity?: Maybe<IdVerificationLink>;
};

export type MutationAddKeylessEngagedUnitArgs = {
  input?: InputMaybe<KeylessEngagedUnitInput>;
};

export type MutationAddUnitsTouredArgs = {
  input: AddUnitsTouredInput;
};

export type MutationBeginTourArgs = {
  input: BeginTourInput;
};

export type MutationBlockMapArgs = {
  input: BlockMapInput;
};

export type MutationCancelTourArgs = {
  input: CancelTourInput;
};

export type MutationCompleteTourMapArgs = {
  input: CompleteTourMapInput;
};

export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};

export type MutationCreateKeylessEntriesArgs = {
  input: CreateKeylessEntriesInput;
};

export type MutationCreateKeylessExceptionArgs = {
  input: CreateKeylessExceptionInput;
};

export type MutationCreateKeylessExceptionsArgs = {
  input: Array<CreateKeylessExceptionInput>;
};

export type MutationCreateTestTourArgs = {
  input: CreateTestTourInput;
};

export type MutationDeleteCommunityPasscodeArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePreArrivalMessageArgs = {
  input: DeletePreArrivalMessageInput;
};

export type MutationDeleteTourMapArgs = {
  input: DeleteTourMapInput;
};

export type MutationExtendTourArgs = {
  input: ExtendTourInput;
};

export type MutationMapsindoorsTourMapArgs = {
  input?: InputMaybe<MapsindoorsTourMapInput>;
};

export type MutationRemoveKeylessEntriesArgs = {
  input: RemoveKeylessEntriesInput;
};

export type MutationRemoveKeylessEntryArgs = {
  input: RemoveKeylessEntryInput;
};

export type MutationRemoveKeylessEntrySkipKastleArgs = {
  input: Scalars['String'];
};

export type MutationRemoveKeylessExceptionArgs = {
  input: RemoveKeylessExceptionInput;
};

export type MutationRemoveKeylessExceptionByIdArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveKeylessExceptionByIdsArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationRemoveKeylessExceptionsArgs = {
  input: Array<RemoveKeylessExceptionInput>;
};

export type MutationRescheduleTourArgs = {
  input: RescheduleTourInput;
};

export type MutationSaveAmenityTourTemplateArgs = {
  input: SaveAmenityTourTemplateInput;
};

export type MutationSaveBaseTemplateArgs = {
  input: SaveBaseTemplateInput;
};

export type MutationSaveCommunityPasscodeArgs = {
  input: SaveCommunityPasscodeInput;
};

export type MutationSaveModelTourTemplateArgs = {
  input: SaveModelTourTemplateInput;
};

export type MutationSavePreArrivalMessageArgs = {
  input: SavePreArrivalMessageInput;
};

export type MutationSaveTourMapArgs = {
  input: SaveTourMapInput;
};

export type MutationSaveTourNoteArgs = {
  input: SaveTourNoteInput;
};

export type MutationScheduleTourArgs = {
  input: ScheduleTourInput;
};

export type MutationSetTourMapReadyArgs = {
  input: SetTourMapReadyInput;
};

export type MutationSetUnitVisitedTimestampArgs = {
  input: SetUnitVisitedTimestampInput;
};

export type MutationSubmitWalkInTourArgs = {
  input: SubmitWalkInTourInput;
};

export type MutationUnNotifyTourAndRemoveKeylessEntriesArgs = {
  input: UnNotifyTourAndRemoveKeylessEntriesInput;
};

export type MutationUpdateKeylessExceptionsArgs = {
  input: UpdateExceptionInput;
};

export type MutationUpdateTourMapArgs = {
  input: UpdateTourMapInput;
};

export type MutationVerifyIdentityArgs = {
  input: VerifyIdentityInput;
};

export type MutationVerifyWebIdentityArgs = {
  input: VerifyWebIdentityInput;
};

export type Needs = {
  __typename?: 'Needs';
  floor?: Maybe<Scalars['String']>;
  floorplanGroup?: Maybe<Scalars['String']>;
  leaseTerm?: Maybe<Scalars['String']>;
  maxRent?: Maybe<Scalars['String']>;
  minRent?: Maybe<Scalars['String']>;
  moveDate?: Maybe<Scalars['String']>;
  occupants?: Maybe<Scalars['String']>;
  pets?: Maybe<Scalars['Boolean']>;
  rentDesired?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Scalars['String']>;
};

export type Opportunities = {
  __typename?: 'Opportunities';
  contact: Contact;
  opportunities?: Maybe<Array<Maybe<Opportunity>>>;
};

export type Opportunity = {
  __typename?: 'Opportunity';
  city?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  createdDate?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  lastModified?: Maybe<Scalars['GraphQLDateTime']>;
  leasingAgentEmail?: Maybe<Scalars['String']>;
  leasingAgentFirstName?: Maybe<Scalars['String']>;
  leasingAgentId?: Maybe<Scalars['ID']>;
  leasingAgentLastName?: Maybe<Scalars['String']>;
  leasingAgentPhone?: Maybe<Scalars['String']>;
  stageLastModified?: Maybe<Scalars['GraphQLDateTime']>;
  stageName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PivotalData = {
  __typename?: 'PivotalData';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  floorplanGroup?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  leaseTerm?: Maybe<Scalars['String']>;
  maxRent?: Maybe<Scalars['String']>;
  minRent?: Maybe<Scalars['String']>;
  moveDate?: Maybe<Scalars['String']>;
  occupants?: Maybe<Scalars['String']>;
  pets?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  rentDesired?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Scalars['String']>;
};

export type Point = {
  __typename?: 'Point';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  type?: Maybe<Scalars['String']>;
};

export type PreArrivalMessage = {
  __typename?: 'PreArrivalMessage';
  agentId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type PreArrivalMessages = {
  __typename?: 'PreArrivalMessages';
  data: Array<PreArrivalMessage>;
};

export type PreArrivalNoteAdmins = {
  __typename?: 'PreArrivalNoteAdmins';
  emails: Array<Scalars['String']>;
};

export type PriceRangeFilter = {
  __typename?: 'PriceRangeFilter';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type PriceRangeFilterInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type PricingAndAvailability = {
  __typename?: 'PricingAndAvailability';
  /** @deprecated No longer supported */
  buildingNumber?: Maybe<Scalars['String']>;
  leaseTerm?: Maybe<Scalars['Int']>;
  rentAmount?: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  unitNumber?: Maybe<Scalars['String']>;
};

export type Property = {
  __typename?: 'Property';
  address?: Maybe<Address>;
  apartments?: Maybe<Array<Apartment>>;
  availableCount?: Maybe<Scalars['Int']>;
  community?: Maybe<Community>;
  communityId?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  floorPlans?: Maybe<Array<Scalars['URL']>>;
  gallery?: Maybe<Array<Scalars['URL']>>;
  id?: Maybe<Scalars['ID']>;
  /** @deprecated use community image to get correct image */
  image?: Maybe<Scalars['URL']>;
  marketingName?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  siteMaps?: Maybe<Array<Scalars['URL']>>;
};

export type PropertyApartmentsArgs = {
  filter?: InputMaybe<ApartmentsFilterInput>;
};

export type PropertyAvailableCountArgs = {
  filter?: InputMaybe<ApartmentsFilterInput>;
};

export type PropertyFloorPlansArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PropertyGalleryArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PropertyImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PropertySiteMapsArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PropertyApartmentInput = {
  apartmentId: Scalars['ID'];
  communityId: Scalars['ID'];
  propertyId: Scalars['ID'];
  prospectEmail: Scalars['String'];
};

export type PropertyApartmentInputs = {
  pairs: Array<PropertyApartmentInput>;
};

export type PropertyUnits = {
  __typename?: 'PropertyUnits';
  communityId?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isModel?: Maybe<Scalars['Boolean']>;
  marketingName?: Maybe<Scalars['String']>;
  units: Array<UnitAvailability>;
};

export type Prospect = {
  __typename?: 'Prospect';
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idVerification?: Maybe<IdVerification>;
  idVerificationDate?: Maybe<Scalars['GraphQLDateTime']>;
  idVerificationDateTime?: Maybe<Scalars['String']>;
  idVerificationStatus?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  needs?: Maybe<Needs>;
  phone?: Maybe<Scalars['String']>;
  tours: Array<Tour>;
};

export type ProspectName = {
  __typename?: 'ProspectName';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type ProspectiveLeaseholderPinDetails = {
  __typename?: 'ProspectiveLeaseholderPinDetails';
  expiration?: Maybe<Scalars['GraphQLDateTime']>;
  pin?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['String']>;
  allFilterOptions: ApartmentsFilter;
  apartment?: Maybe<Apartment>;
  apartments: Array<Apartment>;
  apartmentsFilter?: Maybe<ApartmentsFilter>;
  apartmentsInfo: Array<Maybe<Apartment>>;
  appHealth?: Maybe<AppHealth>;
  appSqlHealth?: Maybe<SqlHealth>;
  availableCommunityApartments?: Maybe<Array<Maybe<Apartment>>>;
  baseMapAdministrators: BaseMapAdministrators;
  baseTemplate?: Maybe<BaseTemplate>;
  brivoInvitation: Array<BrivoDigitalInvitation>;
  buildingUnits: Array<Scalars['String']>;
  cities?: Maybe<Array<City>>;
  cloudSqlHealth?: Maybe<SqlHealth>;
  communities: Array<Community>;
  community?: Maybe<Community>;
  /** @deprecated use apartment query instead, only way of guaranteeing uniqueness */
  communityApartment?: Maybe<Apartment>;
  communityBuildingUnits: Array<PropertyUnits>;
  communityPasscodes: Array<Maybe<CommunityPasscode>>;
  communityTile?: Maybe<CommunityTile>;
  communityUnitLabels?: Maybe<CommunityUnitLabels>;
  confirmEmail?: Maybe<User>;
  contentfulCommunities: Array<CommunityTile>;
  getAmenityTourTemplate?: Maybe<AmenityTourTemplate>;
  getContact?: Maybe<Contact>;
  getModelTourTemplate?: Maybe<ModelTourTemplate>;
  getOpportunities?: Maybe<Opportunities>;
  getProspectByEmail: Array<Prospect>;
  getProspectName?: Maybe<ProspectName>;
  idVerificationExpirationDate?: Maybe<Scalars['GraphQLDateTime']>;
  idVerified?: Maybe<IdVerifiedPayload>;
  isSupportedVersion: Scalars['Boolean'];
  keylessEntries: KeylessEntries;
  keylessEntriesByPropertyAndApartment?: Maybe<Array<KeylessEntry>>;
  keylessEntryInstitution?: Maybe<KeylessEntryInstitution>;
  keylessEntryInstitutions: KeylessEntryInstitutions;
  keylessExceptions: Array<KeylessException>;
  latestIdVerificationSummary?: Maybe<IdVerificationSummary>;
  mostRecentCommunityPasscode?: Maybe<Scalars['JSONObject']>;
  mostRecentPrearrivalMessage?: Maybe<PreArrivalMessage>;
  pinDetails?: Maybe<ProspectiveLeaseholderPinDetails>;
  preArrivalMessages: PreArrivalMessages;
  preArrivalNoteAdmins: PreArrivalNoteAdmins;
  /** @deprecated not in use */
  property?: Maybe<Property>;
  prospectTourMaps?: Maybe<Array<TourMap>>;
  recentlyExtendedToursByProspect?: Maybe<Array<TourMap>>;
  redisHealth?: Maybe<RedisHealth>;
  regions: Array<Region>;
  slots: Array<Slot>;
  tourMap?: Maybe<TourMap>;
  tourMaps?: Maybe<TourMapsReturnType>;
  tourMapsByLeasingEmailAddress?: Maybe<Array<TourMap>>;
  tourStartTimes?: Maybe<Array<TourMap>>;
  tourSummary?: Maybe<TourSummary>;
  unitLeasingPrices?: Maybe<UnitLeasingPricesPayload>;
  /** @deprecated no longer used */
  waiver?: Maybe<Scalars['String']>;
  /** @deprecated no longer used. use idVerificationExpirationDate */
  waiverExpirationDate?: Maybe<Scalars['GraphQLDate']>;
  walkIn?: Maybe<BaseTemplate>;
};

export type QueryAllFilterOptionsArgs = {
  city: Scalars['String'];
};

export type QueryApartmentArgs = {
  buildingUnit: Scalars['String'];
  communityId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type QueryApartmentsArgs = {
  communityId: Scalars['ID'];
  filter?: InputMaybe<ApartmentsFilterInput>;
  propertyId: Scalars['ID'];
};

export type QueryApartmentsFilterArgs = {
  communityId: Scalars['ID'];
};

export type QueryApartmentsInfoArgs = {
  input: Array<ApartmentInfo>;
};

export type QueryAvailableCommunityApartmentsArgs = {
  communityId: Scalars['ID'];
  filters: FiltersType;
};

export type QueryBaseTemplateArgs = {
  communityId: Scalars['ID'];
};

export type QueryBuildingUnitsArgs = {
  propertyId: Scalars['ID'];
};

export type QueryCitiesArgs = {
  region?: InputMaybe<Scalars['String']>;
};

export type QueryCommunitiesArgs = {
  city: Scalars['String'];
  filter?: InputMaybe<ApartmentsFilterInput>;
};

export type QueryCommunityArgs = {
  communityId: Scalars['ID'];
};

export type QueryCommunityApartmentArgs = {
  buildingUnit: Scalars['String'];
  communityId: Scalars['ID'];
};

export type QueryCommunityBuildingUnitsArgs = {
  communityId: Scalars['ID'];
};

export type QueryCommunityPasscodesArgs = {
  communityId: Scalars['String'];
};

export type QueryCommunityTileArgs = {
  communityId: Scalars['ID'];
};

export type QueryCommunityUnitLabelsArgs = {
  apiKey: Scalars['String'];
  tourId: Scalars['ID'];
};

export type QueryConfirmEmailArgs = {
  email: Scalars['String'];
};

export type QueryContentfulCommunitiesArgs = {
  query: Scalars['String'];
};

export type QueryGetAmenityTourTemplateArgs = {
  communityId: Scalars['ID'];
};

export type QueryGetModelTourTemplateArgs = {
  communityId: Scalars['ID'];
};

export type QueryGetProspectByEmailArgs = {
  email: Scalars['EmailAddress'];
  skipCache?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['String']>;
};

export type QueryIsSupportedVersionArgs = {
  version: Scalars['String'];
};

export type QueryKeylessEntriesArgs = {
  tourId: Scalars['ID'];
};

export type QueryKeylessEntriesByPropertyAndApartmentArgs = {
  input: PropertyApartmentInputs;
};

export type QueryKeylessEntryInstitutionArgs = {
  apartmentId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type QueryKeylessEntryInstitutionsArgs = {
  propertyId: Scalars['ID'];
};

export type QueryKeylessExceptionsArgs = {
  communityId?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryMostRecentCommunityPasscodeArgs = {
  communityId: Scalars['String'];
};

export type QueryMostRecentPrearrivalMessageArgs = {
  communityId: Scalars['String'];
};

export type QueryPreArrivalMessagesArgs = {
  communityId: Scalars['String'];
};

export type QueryPropertyArgs = {
  propertyId: Scalars['ID'];
};

export type QuerySlotsArgs = {
  numberOfDays: Scalars['Int'];
  startDate: Scalars['GraphQLDate'];
  tourId: Scalars['ID'];
};

export type QueryTourMapArgs = {
  tourId: Scalars['ID'];
};

export type QueryTourMapsArgs = {
  communityId: Scalars['ID'];
};

export type QueryTourMapsByLeasingEmailAddressArgs = {
  communityId: Scalars['String'];
  email: Scalars['String'];
};

export type QueryTourStartTimesArgs = {
  input?: InputMaybe<TourStartTimeInput>;
};

export type QueryTourSummaryArgs = {
  tourId: Scalars['ID'];
};

export type QueryUnitLeasingPricesArgs = {
  buildingUnit: Scalars['String'];
  communityId: Scalars['ID'];
  filter?: InputMaybe<UnitLeasingPriceFilter>;
  propertyId: Scalars['ID'];
};

export type QueryWalkInArgs = {
  communityId: Scalars['ID'];
};

export type RedisHealth = {
  __typename?: 'RedisHealth';
  cpu?: Maybe<Scalars['JSONObject']>;
  databases?: Maybe<Scalars['JSONObject']>;
  keySpace?: Maybe<Scalars['JSONObject']>;
  memory?: Maybe<Scalars['JSONObject']>;
  status: Scalars['String'];
  uptime?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type Region = {
  __typename?: 'Region';
  cities?: Maybe<Array<City>>;
  name?: Maybe<Scalars['String']>;
};

export type RejectReason = {
  __typename?: 'RejectReason';
  rejectReasonCode?: Maybe<Scalars['String']>;
  rejectReasonDescription?: Maybe<Scalars['String']>;
  rejectReasonDetails?: Maybe<Scalars['JSONObject']>;
};

export type RemoveExceptionResponse = {
  __typename?: 'RemoveExceptionResponse';
  deletedRows: Scalars['Int'];
};

export type RemoveKeylessEntriesInput = {
  tourId: Scalars['ID'];
};

export type RemoveKeylessEntriesPayload = {
  __typename?: 'RemoveKeylessEntriesPayload';
  allRemoved: Scalars['Boolean'];
  keylessEntries: Array<KeylessEntry>;
};

export type RemoveKeylessEntryInput = {
  apartmentId: Scalars['ID'];
  communityId: Scalars['ID'];
  propertyId: Scalars['ID'];
  tourId: Scalars['ID'];
};

export type RemoveKeylessEntryPayload = {
  __typename?: 'RemoveKeylessEntryPayload';
  allRemoved: Scalars['Boolean'];
  keylessEntries: Array<KeylessEntry>;
};

export type RemoveKeylessExceptionInput = {
  apartmentId: Scalars['String'];
  communityId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type RescheduleTourInput = {
  fromForceQuit?: InputMaybe<Scalars['Boolean']>;
  slotDateTime: Scalars['GraphQLDateTime'];
  tourId: Scalars['ID'];
};

export type RescheduleTourPayload = {
  __typename?: 'RescheduleTourPayload';
  rescheduledTour?: Maybe<RescheduledTour>;
};

export type RescheduledTour = {
  __typename?: 'RescheduledTour';
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  fromBookingId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  prospectEmail?: Maybe<Scalars['String']>;
  slotDateTime?: Maybe<Scalars['GraphQLDateTime']>;
  toBookingId?: Maybe<Scalars['String']>;
  tourId?: Maybe<Scalars['String']>;
  tourMap?: Maybe<TourMap>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type RouteProperties = {
  __typename?: 'RouteProperties';
  isDriving?: Maybe<Scalars['Boolean']>;
  route?: Maybe<Scalars['Int']>;
};

export type RouteStop = {
  __typename?: 'RouteStop';
  isExit?: Maybe<Scalars['Boolean']>;
  navHelp?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['Int']>;
};

export type SaveAmenityTourTemplateInput = {
  communityId: Scalars['ID'];
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
};

export type SaveBaseTemplateInput = {
  communityId: Scalars['ID'];
  isModel: Scalars['Boolean'];
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
};

export type SaveCommunityPasscodeInput = {
  communityId: Scalars['String'];
  enableForPreArrival: Scalars['Boolean'];
  passcode: Scalars['String'];
};

export type SaveModelTourTemplateInput = {
  communityId: Scalars['ID'];
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
};

export type SavePreArrivalMessageInput = {
  communityId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
  name: Scalars['String'];
};

export type SavePreArrivalMessagePayload = {
  __typename?: 'SavePreArrivalMessagePayload';
  preArrivalMessage?: Maybe<PreArrivalMessage>;
};

export type SaveTourMapInput = {
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  pivotalData?: InputMaybe<Scalars['JSONObject']>;
  propertyId: Scalars['String'];
  prospectEmail: Scalars['EmailAddress'];
  tile?: InputMaybe<Scalars['JSONObject']>;
  tour: TourInput;
  tourId: Scalars['String'];
};

export type SaveTourNoteInput = {
  notes?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type ScheduleTourInfo = {
  __typename?: 'ScheduleTourInfo';
  hasInPersonTours?: Maybe<Scalars['Boolean']>;
  hyLyId?: Maybe<Scalars['String']>;
  slots?: Maybe<Array<Slot>>;
};

export type ScheduleTourInput = {
  comment?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  floorLevel?: InputMaybe<TourFloorLevel>;
  floorPlan?: InputMaybe<TourFloorPlan>;
  lastName: Scalars['String'];
  moveDate?: InputMaybe<Scalars['GraphQLDate']>;
  pets?: InputMaybe<TourPet>;
  phoneNumber: Scalars['String'];
  propertyId: Scalars['String'];
  slotDateTime: Scalars['GraphQLDateTime'];
  smsOptIn?: InputMaybe<Scalars['Boolean']>;
};

export type ScheduleTourPayload = {
  __typename?: 'ScheduleTourPayload';
  id?: Maybe<Scalars['String']>;
  slotDateTime?: Maybe<Scalars['GraphQLDateTime']>;
  status?: Maybe<BookingStatus>;
};

export type SetTourMapReadyInput = {
  buildTimes?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['GraphQLDateTime']>>>>>;
  email?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  notifier?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type SetUnitVisitedTimestampInput = {
  tourId: Scalars['ID'];
  unitId: Scalars['String'];
};

export type Size = {
  __typename?: 'Size';
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type Slot = {
  __typename?: 'Slot';
  dateTime?: Maybe<Scalars['GraphQLDateTime']>;
  duration?: Maybe<Scalars['Int']>;
};

export type SqlHealth = {
  __typename?: 'SqlHealth';
  connectionPool?: Maybe<Scalars['JSONObject']>;
  database?: Maybe<Scalars['JSONObject']>;
  status: Scalars['String'];
  uptime?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type SubmitWalkInTourInput = {
  communityId: Scalars['String'];
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  floorLevel?: InputMaybe<TourFloorLevel>;
  floorPlan?: InputMaybe<TourFloorPlan>;
  isAmenity?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  moveDate?: InputMaybe<Scalars['GraphQLDate']>;
  pets?: InputMaybe<TourPet>;
  phoneNumber: Scalars['String'];
  preferences?: InputMaybe<Scalars['String']>;
  tourType?: InputMaybe<Scalars['String']>;
};

export type SubmitWalkInTourPayload = {
  __typename?: 'SubmitWalkInTourPayload';
  walkInTour?: Maybe<WalkInTour>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Void']>;
  idVerified?: Maybe<IdVerifiedPayload>;
  tourUpdated?: Maybe<TourMap>;
};

export type Tour = {
  __typename?: 'Tour';
  bookingId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['GraphQLDateTime']>;
  communityContactInfo?: Maybe<ContactInfo>;
  communityId?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  contentfulData?: Maybe<ContentfulData>;
  ignoreRestrictions?: Maybe<Scalars['Boolean']>;
  isAmenity?: Maybe<Scalars['Boolean']>;
  isModel?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  leasingAgent?: Maybe<LeasingAgent>;
  leasingEmailAddress?: Maybe<Scalars['EmailAddress']>;
  leasingPhoneNumber?: Maybe<Scalars['String']>;
  mapDesigner?: Maybe<Scalars['String']>;
  preArrivalInstructions?: Maybe<Scalars['String']>;
  /** @deprecated use property on TourMap level */
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tourId?: Maybe<Scalars['ID']>;
  tourMap?: Maybe<TourMap>;
  units?: Maybe<Array<Scalars['String']>>;
  waiverSigned?: Maybe<Scalars['Boolean']>;
};

export enum TourFloorLevel {
  Bottom = 'BOTTOM',
  Middle = 'MIDDLE',
  NoPreference = 'NO_PREFERENCE',
  Top = 'TOP',
}

export enum TourFloorPlan {
  AffordableHousing = 'AFFORDABLE_HOUSING',
  OneBedroom = 'ONE_BEDROOM',
  OneBedroomPlusDen = 'ONE_BEDROOM_PLUS_DEN',
  Studio = 'STUDIO',
  ThreeBedroom = 'THREE_BEDROOM',
  TwoBedroom = 'TWO_BEDROOM',
  TwoBedroomPlusDen = 'TWO_BEDROOM_PLUS_DEN',
}

export type TourInput = {
  communityId: Scalars['String'];
  communityName?: InputMaybe<Scalars['String']>;
  isModel?: InputMaybe<Scalars['Boolean']>;
  leasingAgent?: InputMaybe<LeasingAgentInput>;
  leasingEmailAddress?: InputMaybe<Scalars['String']>;
  leasingPhoneNumber?: InputMaybe<Scalars['String']>;
  mapDesigner?: InputMaybe<Scalars['String']>;
  preArrivalInstructions?: InputMaybe<Scalars['String']>;
  startTime: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
  waiverSigned: Scalars['Boolean'];
};

export type TourMap = {
  __typename?: 'TourMap';
  agentId?: Maybe<Scalars['String']>;
  beganTourAt?: Maybe<Scalars['GraphQLDateTime']>;
  cancelledAt?: Maybe<Scalars['GraphQLDateTime']>;
  completedAt?: Maybe<Scalars['GraphQLDateTime']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  extendedAt?: Maybe<Scalars['GraphQLDateTime']>;
  hasKeylessUnits?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  ignoreRestrictions?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  keylessEnabled?: Maybe<Scalars['Boolean']>;
  keylessEntries?: Maybe<KeylessEntries>;
  keylessStatus?: Maybe<KeylessStatus>;
  mapData?: Maybe<MapData>;
  metadata?: Maybe<Scalars['JSONObject']>;
  notes?: Maybe<Scalars['String']>;
  pivotalData?: Maybe<PivotalData>;
  property?: Maybe<Property>;
  propertyId?: Maybe<Scalars['String']>;
  prospectEmail?: Maybe<Scalars['EmailAddress']>;
  prospectNotifiedAt?: Maybe<Scalars['GraphQLDateTime']>;
  tile?: Maybe<Scalars['JSONObject']>;
  tour?: Maybe<Tour>;
  tourId?: Maybe<Scalars['String']>;
  unitInfo?: Maybe<Array<UnitInfo>>;
  unitsVisitedAt?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type TourMapsReturnType = {
  __typename?: 'TourMapsReturnType';
  community?: Maybe<Array<TourMap>>;
  user?: Maybe<Array<TourMap>>;
};

export enum TourPet {
  Cat = 'CAT',
  Dog = 'DOG',
  No = 'NO',
}

export type TourStartTimeInput = {
  tourIds: Array<InputMaybe<Scalars['ID']>>;
};

export type TourSummary = {
  __typename?: 'TourSummary';
  hideApplyNow: Scalars['Boolean'];
  tourId?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Maybe<TouredUnit>>>;
};

export type TouredUnit = {
  __typename?: 'TouredUnit';
  apartment?: Maybe<Apartment>;
  buildingUnit?: Maybe<Scalars['String']>;
  /**
   * Community Id is pulled from unit marker, not apartment object.
   * This id can be different than the top-level community ids for super map communities.
   */
  communityId?: Maybe<Scalars['String']>;
  communityName?: Maybe<Scalars['String']>;
  feedback?: Maybe<Feedback>;
  floorplanId?: Maybe<Scalars['String']>;
  floorplanName?: Maybe<Scalars['String']>;
  /** @deprecated use isModel field on Apartment object */
  isModel?: Maybe<Scalars['Boolean']>;
  markerType?: Maybe<MarkerType>;
  order?: Maybe<Scalars['Int']>;
  propertyId?: Maybe<Scalars['String']>;
};

export type UnNotifyTourAndRemoveKeylessEntriesInput = {
  tourIds: Array<InputMaybe<Scalars['ID']>>;
};

export type UnitAvailability = {
  __typename?: 'UnitAvailability';
  availability?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  /** @deprecated this is a pivotal artifact, use floorplanUniqueID instead */
  floorplanCRMID?: Maybe<Scalars['String']>;
  floorplanName?: Maybe<Scalars['String']>;
  floorplanUniqueID?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UnitInfo = {
  __typename?: 'UnitInfo';
  buildingUnit?: Maybe<Scalars['String']>;
  pricingAndAvailability?: Maybe<CachedPricing>;
  property?: Maybe<Property>;
};

export type UnitLeasingPrice = {
  __typename?: 'UnitLeasingPrice';
  date?: Maybe<Scalars['GraphQLDate']>;
  price?: Maybe<Scalars['Int']>;
  term?: Maybe<Scalars['Int']>;
};

export type UnitLeasingPriceFilter = {
  moveInDate?: InputMaybe<Scalars['GraphQLDate']>;
};

export type UnitLeasingPricesPayload = {
  __typename?: 'UnitLeasingPricesPayload';
  unitLeasingPrices?: Maybe<Array<UnitLeasingPrice>>;
};

export type UpdateExceptionInput = {
  communityId: Scalars['String'];
  exceptions: Array<KeylessUpdateInput>;
};

export type UpdateExceptionsResponse = {
  __typename?: 'UpdateExceptionsResponse';
  deletedRows: Scalars['Int'];
  exceptions: Array<KeylessException>;
};

export type UpdateProspectIdVerificationInput = {
  isVerified: Scalars['Boolean'];
  prospectEmail: Scalars['EmailAddress'];
};

export type UpdateProspectIdVerificationPayload = {
  __typename?: 'UpdateProspectIdVerificationPayload';
  idVerificationExpirationDate?: Maybe<Scalars['GraphQLDateTime']>;
};

export type UpdateTourMapInput = {
  completedAt?: InputMaybe<Scalars['GraphQLDateTime']>;
  mapData?: InputMaybe<Scalars['JSONObject']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  pivotalData?: InputMaybe<Scalars['JSONObject']>;
  prospectEmail?: InputMaybe<Scalars['EmailAddress']>;
  tile?: InputMaybe<Scalars['JSONObject']>;
  tour?: InputMaybe<TourInput>;
  tourId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  name: Scalars['String'];
  userId: Scalars['String'];
};

export type VerifyIdentityInput = {
  email: Scalars['String'];
  useBiometricScan: Scalars['Boolean'];
};

export type VerifyWebIdentityInput = {
  email: Scalars['String'];
  options: VerifyWebIdentityOptions;
  refreshToken: Scalars['String'];
  useBiometricScan: Scalars['Boolean'];
};

export type VerifyWebIdentityOptions = {
  errorUrl: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  successUrl: Scalars['String'];
};

export type WalkInTour = {
  __typename?: 'WalkInTour';
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type MapDataFieldsFragment = {
  __typename?: 'MapData';
  type?: string | null;
  features?: Array<{
    __typename?: 'Feature';
    id?: string | null;
    type?: string | null;
    properties?:
      | {
          __typename: 'MarkerProperties';
          apartmentId?: string | null;
          amenityTitle?: string | null;
          elevatorNumber?: string | null;
          communityId?: string | null;
          communityName?: string | null;
          floorplanUniqueID?: string | null;
          floorplanName?: string | null;
          floor?: number | null;
          onesiteId?: string | null;
          gateNumber?: string | null;
          isModel?: boolean | null;
          isKeyless?: boolean | null;
          isHidden?: boolean | null;
          isOnRoute?: boolean | null;
          opacityIncreased?: boolean | null;
          icon?: string | null;
          iconBase?: string | null;
          markerType?: MarkerType | null;
          notes?: string | null;
          order?: number | null;
          removeFromMPRoute?: boolean | null;
          unitId?: string | null;
          label?: string | null;
          isPinEnabled?: boolean | null;
          autoNotes?: Array<string | null> | null;
          routeStops?: Array<{
            __typename?: 'RouteStop';
            route?: number | null;
            navHelp?: string | null;
            isExit?: boolean | null;
          }> | null;
          mpLocation?: {
            __typename?: 'MPLocation';
            id?: string | null;
            type?: string | null;
            instructions?: Array<string | null> | null;
            geometry?: {
              __typename?: 'LocationGeometry';
              bbox?: Array<number | null> | null;
              coordinates?: string | null;
              type?: string | null;
            } | null;
            properties?: {
              __typename?: 'MPProperties';
              aliases?: Array<string | null> | null;
              building?: string | null;
              description?: string | null;
              externalId?: string | null;
              fields?: string | null;
              floor?: string | null;
              floorName?: string | null;
              imageURL?: string | null;
              locationType?: string | null;
              mapElement?: string | null;
              name?: string | null;
              type?: string | null;
              venue?: string | null;
              roomId?: string | null;
              parentId?: string | null;
              status?: number | null;
              venueId?: string | null;
              buildingId?: string | null;
              anchor?: { __typename?: 'Point'; type?: string | null; coordinates?: Array<number | null> | null } | null;
            } | null;
          } | null;
        }
      | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
      | null;
    geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
  }> | null;
};

export type TourStartTimesQueryVariables = Exact<{
  input?: InputMaybe<TourStartTimeInput>;
}>;

export type TourStartTimesQuery = {
  __typename?: 'Query';
  tourStartTimes?: Array<{
    __typename?: 'TourMap';
    id?: string | null;
    tourId?: string | null;
    tour?: { __typename?: 'Tour'; tourId?: string | null; startTime?: string | null } | null;
  }> | null;
};

export type GetTourMapQueryVariables = Exact<{
  tourId: Scalars['ID'];
}>;

export type GetTourMapQuery = {
  __typename?: 'Query';
  tourMap?: {
    __typename?: 'TourMap';
    id?: string | null;
    tourId?: string | null;
    prospectEmail?: any | null;
    prospectNotifiedAt?: any | null;
    propertyId?: string | null;
    isBlocked?: boolean | null;
    metadata?: any | null;
    completedAt?: any | null;
    unitsVisitedAt?: any | null;
    beganTourAt?: any | null;
    extendedAt?: any | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
    pivotalData?: {
      __typename?: 'PivotalData';
      id?: string | null;
      floorplanGroup?: string | null;
      leaseTerm?: string | null;
      occupants?: string | null;
      moveDate?: string | null;
      minRent?: string | null;
      maxRent?: string | null;
      rentDesired?: string | null;
      pets?: boolean | null;
      vehicles?: string | null;
      floor?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    tour?: {
      __typename?: 'Tour';
      tourId?: string | null;
      communityId?: string | null;
      communityName?: string | null;
      startTime?: string | null;
      status?: string | null;
      mapDesigner?: string | null;
      preArrivalInstructions?: string | null;
      leasingEmailAddress?: any | null;
      leasingPhoneNumber?: string | null;
      leasingAgent?: {
        __typename?: 'LeasingAgent';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null;
    unitInfo?: Array<{
      __typename?: 'UnitInfo';
      buildingUnit?: string | null;
      property?: { __typename?: 'Property'; marketingName?: string | null } | null;
      pricingAndAvailability?: {
        __typename?: 'CachedPricing';
        leaseTerm?: number | null;
        rentAmount?: number | null;
      } | null;
    }> | null;
  } | null;
};

export type GetProspectInfoQueryVariables = Exact<{
  tourId: Scalars['ID'];
}>;

export type GetProspectInfoQuery = {
  __typename?: 'Query';
  tourMap?: {
    __typename?: 'TourMap';
    id?: string | null;
    prospectEmail?: any | null;
    propertyId?: string | null;
    createdAt?: any | null;
    completedAt?: any | null;
    metadata?: any | null;
    prospectNotifiedAt?: any | null;
    tour?: {
      __typename?: 'Tour';
      tourId?: string | null;
      communityId?: string | null;
      communityName?: string | null;
      waiverSigned?: boolean | null;
      startTime?: string | null;
      mapDesigner?: string | null;
      leasingEmailAddress?: any | null;
      leasingPhoneNumber?: string | null;
      leasingAgent?: { __typename?: 'LeasingAgent'; firstName?: string | null; lastName?: string | null } | null;
    } | null;
  } | null;
};

export type GetCommunityIdQueryVariables = Exact<{
  tourId: Scalars['ID'];
}>;

export type GetCommunityIdQuery = {
  __typename?: 'Query';
  tourMap?: {
    __typename?: 'TourMap';
    id?: string | null;
    tour?: { __typename?: 'Tour'; tourId?: string | null; communityId?: string | null } | null;
  } | null;
};

export type GetCommunityFiltersQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetCommunityFiltersQuery = {
  __typename?: 'Query';
  community?: {
    __typename?: 'Community';
    id?: string | null;
    apartmentsFilter?: {
      __typename?: 'ApartmentsFilter';
      bedrooms?: Array<string> | null;
      bathrooms?: Array<number> | null;
      floor?: Array<number> | null;
      priceRange?: { __typename?: 'PriceRangeFilter'; min?: number | null; max?: number | null } | null;
    } | null;
  } | null;
};

export type GetUnitIDsQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetUnitIDsQuery = {
  __typename?: 'Query';
  communityBuildingUnits: Array<{
    __typename?: 'PropertyUnits';
    marketingName?: string | null;
    communityId?: string | null;
    communityName?: string | null;
    isModel?: boolean | null;
    id?: string | null;
    units: Array<{
      __typename?: 'UnitAvailability';
      id: string;
      availability?: string | null;
      floorplanName?: string | null;
      floor?: number | null;
      floorplanUniqueID?: string | null;
    }>;
  }>;
};

export type ConfirmEmailExistsQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type ConfirmEmailExistsQuery = {
  __typename?: 'Query';
  confirmEmail?: { __typename?: 'User'; emailVerified: boolean; email: string; name: string; userId: string } | null;
};

export type PreArrivalNoteAdminsQueryVariables = Exact<{ [key: string]: never }>;

export type PreArrivalNoteAdminsQuery = {
  __typename?: 'Query';
  preArrivalNoteAdmins: { __typename?: 'PreArrivalNoteAdmins'; emails: Array<string> };
};

export type BaseMapAdministratorsQueryVariables = Exact<{ [key: string]: never }>;

export type BaseMapAdministratorsQuery = {
  __typename?: 'Query';
  baseMapAdministrators: { __typename?: 'BaseMapAdministrators'; emails: Array<string> };
};

export type TourMapsByLeasingEmailAddressQueryVariables = Exact<{
  email: Scalars['String'];
  communityId: Scalars['String'];
}>;

export type TourMapsByLeasingEmailAddressQuery = {
  __typename?: 'Query';
  tourMapsByLeasingEmailAddress?: Array<{
    __typename?: 'TourMap';
    agentId?: string | null;
    tourId?: string | null;
    prospectEmail?: any | null;
    propertyId?: string | null;
    metadata?: any | null;
    createdAt?: any | null;
    tour?: {
      __typename?: 'Tour';
      tourId?: string | null;
      communityId?: string | null;
      propertyId?: string | null;
      communityName?: string | null;
      startTime?: string | null;
      status?: string | null;
      waiverSigned?: boolean | null;
      units?: Array<string> | null;
      leasingEmailAddress?: any | null;
      leasingPhoneNumber?: string | null;
      bookingId?: string | null;
      isModel?: boolean | null;
      preArrivalInstructions?: string | null;
      mapDesigner?: string | null;
      cancelledAt?: any | null;
      isTest?: boolean | null;
      leasingAgent?: {
        __typename?: 'LeasingAgent';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      contentfulData?: { __typename?: 'ContentfulData'; zingleNumber?: string | null; hasChat?: boolean | null } | null;
    } | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
    pivotalData?: { __typename?: 'PivotalData'; firstName?: string | null; lastName?: string | null } | null;
  }> | null;
};

export type GetProspectQueryVariables = Exact<{
  email: Scalars['EmailAddress'];
  startTime: Scalars['String'];
  skipCache?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetProspectQuery = {
  __typename?: 'Query';
  getProspectByEmail: Array<{
    __typename?: 'Prospect';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: any | null;
    idVerificationStatus?: string | null;
    idVerificationDateTime?: string | null;
    idVerification?: {
      __typename?: 'IdVerification';
      id?: string | null;
      idUsState?: string | null;
      isValid?: boolean | null;
      verificationStatus?: string | null;
      createdAt?: any | null;
      metadata?: any | null;
      nameCheckFailed?: boolean | null;
      identityVerification?: {
        __typename?: 'IdentityVerification';
        validity?: boolean | null;
        similarity?: IdentityVerificationSimilarity | null;
        reason?: string | null;
      } | null;
      errorDetails?: {
        __typename?: 'IdVerificationErrorDetails';
        id?: string | null;
        internalTitle?: string | null;
      } | null;
    } | null;
    needs?: {
      __typename?: 'Needs';
      floorplanGroup?: string | null;
      moveDate?: string | null;
      leaseTerm?: string | null;
      occupants?: string | null;
      rentDesired?: string | null;
      pets?: boolean | null;
      vehicles?: string | null;
      floor?: string | null;
    } | null;
    tours: Array<{
      __typename?: 'Tour';
      tourId?: string | null;
      communityId?: string | null;
      communityName?: string | null;
      propertyId?: string | null;
      startTime?: string | null;
      isModel?: boolean | null;
      isAmenity?: boolean | null;
      status?: string | null;
      waiverSigned?: boolean | null;
      units?: Array<string> | null;
      leasingEmailAddress?: any | null;
      leasingPhoneNumber?: string | null;
      mapDesigner?: string | null;
      isTest?: boolean | null;
      leasingAgent?: {
        __typename?: 'LeasingAgent';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      tourMap?: {
        __typename?: 'TourMap';
        id?: string | null;
        tourId?: string | null;
        isBlocked?: boolean | null;
        completedAt?: any | null;
        beganTourAt?: any | null;
        extendedAt?: any | null;
        createdAt?: any | null;
        prospectNotifiedAt?: any | null;
        updatedAt?: any | null;
        cancelledAt?: any | null;
        keylessStatus?: KeylessStatus | null;
        ignoreRestrictions?: boolean | null;
        mapData?: { __typename?: 'MapData'; type?: string | null } | null;
      } | null;
    }>;
  }>;
};

export type GetAppHealthQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppHealthQuery = {
  __typename?: 'Query';
  appHealth?: {
    __typename?: 'AppHealth';
    environment: string;
    version: string;
    uptime: string;
    cpu?: any | null;
    memory?: any | null;
  } | null;
};

export type GetAppSqlHealthQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppSqlHealthQuery = {
  __typename?: 'Query';
  appSqlHealth?: {
    __typename?: 'SqlHealth';
    status: string;
    version?: string | null;
    uptime?: string | null;
    database?: any | null;
    connectionPool?: any | null;
  } | null;
};

export type GetCloudSqlHealthQueryVariables = Exact<{ [key: string]: never }>;

export type GetCloudSqlHealthQuery = {
  __typename?: 'Query';
  cloudSqlHealth?: {
    __typename?: 'SqlHealth';
    status: string;
    version?: string | null;
    uptime?: string | null;
    database?: any | null;
    connectionPool?: any | null;
  } | null;
};

export type GetRedisHealthQueryVariables = Exact<{ [key: string]: never }>;

export type GetRedisHealthQuery = {
  __typename?: 'Query';
  redisHealth?: {
    __typename?: 'RedisHealth';
    status: string;
    version?: string | null;
    uptime?: string | null;
    cpu?: any | null;
    memory?: any | null;
    databases?: any | null;
    keySpace?: any | null;
  } | null;
};

export type FlushDataCacheMutationVariables = Exact<{ [key: string]: never }>;

export type FlushDataCacheMutation = { __typename?: 'Mutation'; flushDataCache: string };

export type FlushAllCacheMutationVariables = Exact<{ [key: string]: never }>;

export type FlushAllCacheMutation = { __typename?: 'Mutation'; flushAllCache: string };

export type SaveTourMapMutationVariables = Exact<{
  input: SaveTourMapInput;
}>;

export type SaveTourMapMutation = {
  __typename?: 'Mutation';
  saveTourMap?: { __typename?: 'TourMap'; id?: string | null; metadata?: any | null } | null;
};

export type UpdateTourMapMutationVariables = Exact<{
  input: UpdateTourMapInput;
}>;

export type UpdateTourMapMutation = {
  __typename?: 'Mutation';
  updateTourMap?: { __typename?: 'TourMap'; id?: string | null; metadata?: any | null } | null;
};

export type BlockMapMutationVariables = Exact<{
  input: BlockMapInput;
}>;

export type BlockMapMutation = {
  __typename?: 'Mutation';
  blockMap?: { __typename?: 'TourMap'; id?: string | null } | null;
};

export type UnNotifyTourMapAndRemoveKeylessEntriesMutationVariables = Exact<{
  input: UnNotifyTourAndRemoveKeylessEntriesInput;
}>;

export type UnNotifyTourMapAndRemoveKeylessEntriesMutation = {
  __typename?: 'Mutation';
  unNotifyTourAndRemoveKeylessEntries: Array<{
    __typename?: 'RemoveKeylessEntryPayload';
    allRemoved: boolean;
    keylessEntries: Array<{
      __typename?: 'KeylessEntry';
      id: string;
      sequence: number;
      tourId: string;
      error?: string | null;
      createdAt: any;
      updatedAt: any;
      apartmentId?: string | null;
      communityId?: string | null;
      prospectEmail?: string | null;
      propertyId: string;
      prospectPhoneNumber?: string | null;
      startTime?: any | null;
      deleted?: boolean | null;
    }>;
  } | null>;
};

export type DeleteTourMapMutationVariables = Exact<{
  input: DeleteTourMapInput;
}>;

export type DeleteTourMapMutation = {
  __typename?: 'Mutation';
  deleteTourMap?: {
    __typename?: 'TourMap';
    id?: string | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type SaveBaseTemplateMutationVariables = Exact<{
  input: SaveBaseTemplateInput;
}>;

export type SaveBaseTemplateMutation = {
  __typename?: 'Mutation';
  saveBaseTemplate?: {
    __typename?: 'BaseTemplate';
    id?: string | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type SaveModelTourTemplateMutationVariables = Exact<{
  input: SaveModelTourTemplateInput;
}>;

export type SaveModelTourTemplateMutation = {
  __typename?: 'Mutation';
  saveModelTourTemplate?: {
    __typename?: 'ModelTourTemplate';
    id?: string | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type SaveAmenityTourTemplateMutationVariables = Exact<{
  input: SaveAmenityTourTemplateInput;
}>;

export type SaveAmenityTourTemplateMutation = {
  __typename?: 'Mutation';
  saveAmenityTourTemplate?: {
    __typename?: 'AmenityTourTemplate';
    id?: string | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type SetTourMapReadyMutationVariables = Exact<{
  input: SetTourMapReadyInput;
}>;

export type SetTourMapReadyMutation = {
  __typename?: 'Mutation';
  setTourMapReady?: { __typename?: 'TourMap'; id?: string | null } | null;
};

export type GetTileQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetTileQuery = {
  __typename?: 'Query';
  communityTile?: {
    __typename?: 'CommunityTile';
    communityName?: string | null;
    mapBoxId?: string | null;
    bounds?: any | null;
    bearing?: number | null;
    isKeyless?: boolean | null;
    hasAutoMarkerPlacement?: boolean | null;
    hideFloors?: boolean | null;
    enablePreArrivalInstructionLockDown?: boolean | null;
    requiredAmenities?: Array<string> | null;
    mapsIndoorsVenueId?: string | null;
    mapsPeopleEnabled?: boolean | null;
    shouldShowMapsPeopleExperiencePopUp?: boolean | null;
    unitKeylessProvider?: string | null;
    amenityKeylessProvider?: string | null;
    maximumTravelDistance?: number | null;
    mapsIndoorsApi?: string | null;
    preArrivalInstructions?: string | null;
  } | null;
};

export type GetBaseTemplateQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetBaseTemplateQuery = {
  __typename?: 'Query';
  baseTemplate?: {
    __typename?: 'BaseTemplate';
    id?: string | null;
    updatedAt?: any | null;
    metadata?: any | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetToursByPropertyQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetToursByPropertyQuery = {
  __typename?: 'Query';
  tourMaps?: {
    __typename?: 'TourMapsReturnType';
    user?: Array<{
      __typename?: 'TourMap';
      id?: string | null;
      isBlocked?: boolean | null;
      metadata?: any | null;
      tourId?: string | null;
      createdAt?: any | null;
      mapData?: {
        __typename?: 'MapData';
        type?: string | null;
        features?: Array<{
          __typename?: 'Feature';
          id?: string | null;
          type?: string | null;
          properties?:
            | {
                __typename: 'MarkerProperties';
                apartmentId?: string | null;
                amenityTitle?: string | null;
                elevatorNumber?: string | null;
                communityId?: string | null;
                communityName?: string | null;
                floorplanUniqueID?: string | null;
                floorplanName?: string | null;
                floor?: number | null;
                onesiteId?: string | null;
                gateNumber?: string | null;
                isModel?: boolean | null;
                isKeyless?: boolean | null;
                isHidden?: boolean | null;
                isOnRoute?: boolean | null;
                opacityIncreased?: boolean | null;
                icon?: string | null;
                iconBase?: string | null;
                markerType?: MarkerType | null;
                notes?: string | null;
                order?: number | null;
                removeFromMPRoute?: boolean | null;
                unitId?: string | null;
                label?: string | null;
                isPinEnabled?: boolean | null;
                autoNotes?: Array<string | null> | null;
                routeStops?: Array<{
                  __typename?: 'RouteStop';
                  route?: number | null;
                  navHelp?: string | null;
                  isExit?: boolean | null;
                }> | null;
                mpLocation?: {
                  __typename?: 'MPLocation';
                  id?: string | null;
                  type?: string | null;
                  instructions?: Array<string | null> | null;
                  geometry?: {
                    __typename?: 'LocationGeometry';
                    bbox?: Array<number | null> | null;
                    coordinates?: string | null;
                    type?: string | null;
                  } | null;
                  properties?: {
                    __typename?: 'MPProperties';
                    aliases?: Array<string | null> | null;
                    building?: string | null;
                    description?: string | null;
                    externalId?: string | null;
                    fields?: string | null;
                    floor?: string | null;
                    floorName?: string | null;
                    imageURL?: string | null;
                    locationType?: string | null;
                    mapElement?: string | null;
                    name?: string | null;
                    type?: string | null;
                    venue?: string | null;
                    roomId?: string | null;
                    parentId?: string | null;
                    status?: number | null;
                    venueId?: string | null;
                    buildingId?: string | null;
                    anchor?: {
                      __typename?: 'Point';
                      type?: string | null;
                      coordinates?: Array<number | null> | null;
                    } | null;
                  } | null;
                } | null;
              }
            | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
            | null;
          geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
        }> | null;
      } | null;
      pivotalData?: {
        __typename?: 'PivotalData';
        id?: string | null;
        floorplanGroup?: string | null;
        moveDate?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      tour?: {
        __typename?: 'Tour';
        tourId?: string | null;
        startTime?: string | null;
        mapDesigner?: string | null;
      } | null;
    }> | null;
    community?: Array<{
      __typename?: 'TourMap';
      id?: string | null;
      isBlocked?: boolean | null;
      metadata?: any | null;
      tourId?: string | null;
      createdAt?: any | null;
      mapData?: {
        __typename?: 'MapData';
        type?: string | null;
        features?: Array<{
          __typename?: 'Feature';
          id?: string | null;
          type?: string | null;
          properties?:
            | {
                __typename: 'MarkerProperties';
                apartmentId?: string | null;
                amenityTitle?: string | null;
                elevatorNumber?: string | null;
                communityId?: string | null;
                communityName?: string | null;
                floorplanUniqueID?: string | null;
                floorplanName?: string | null;
                floor?: number | null;
                onesiteId?: string | null;
                gateNumber?: string | null;
                isModel?: boolean | null;
                isKeyless?: boolean | null;
                isHidden?: boolean | null;
                isOnRoute?: boolean | null;
                opacityIncreased?: boolean | null;
                icon?: string | null;
                iconBase?: string | null;
                markerType?: MarkerType | null;
                notes?: string | null;
                order?: number | null;
                removeFromMPRoute?: boolean | null;
                unitId?: string | null;
                label?: string | null;
                isPinEnabled?: boolean | null;
                autoNotes?: Array<string | null> | null;
                routeStops?: Array<{
                  __typename?: 'RouteStop';
                  route?: number | null;
                  navHelp?: string | null;
                  isExit?: boolean | null;
                }> | null;
                mpLocation?: {
                  __typename?: 'MPLocation';
                  id?: string | null;
                  type?: string | null;
                  instructions?: Array<string | null> | null;
                  geometry?: {
                    __typename?: 'LocationGeometry';
                    bbox?: Array<number | null> | null;
                    coordinates?: string | null;
                    type?: string | null;
                  } | null;
                  properties?: {
                    __typename?: 'MPProperties';
                    aliases?: Array<string | null> | null;
                    building?: string | null;
                    description?: string | null;
                    externalId?: string | null;
                    fields?: string | null;
                    floor?: string | null;
                    floorName?: string | null;
                    imageURL?: string | null;
                    locationType?: string | null;
                    mapElement?: string | null;
                    name?: string | null;
                    type?: string | null;
                    venue?: string | null;
                    roomId?: string | null;
                    parentId?: string | null;
                    status?: number | null;
                    venueId?: string | null;
                    buildingId?: string | null;
                    anchor?: {
                      __typename?: 'Point';
                      type?: string | null;
                      coordinates?: Array<number | null> | null;
                    } | null;
                  } | null;
                } | null;
              }
            | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
            | null;
          geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
        }> | null;
      } | null;
      pivotalData?: {
        __typename?: 'PivotalData';
        id?: string | null;
        floorplanGroup?: string | null;
        moveDate?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      tour?: {
        __typename?: 'Tour';
        tourId?: string | null;
        startTime?: string | null;
        mapDesigner?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type GetContentfulCommunitiesQueryVariables = Exact<{
  query: Scalars['String'];
  isModel?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetContentfulCommunitiesQuery = {
  __typename?: 'Query';
  contentfulCommunities: Array<{
    __typename?: 'CommunityTile';
    communityId?: string | null;
    enablePreArrivalInstructionLockDown?: boolean | null;
    communityName?: string | null;
    mapsPeopleEnabled?: boolean | null;
    lastEdited?: any | null;
    community?: {
      __typename?: 'Community';
      properties?: Array<{ __typename?: 'Property'; id?: string | null; marketingName?: string | null }> | null;
    } | null;
  }>;
};

export type GetWalkInTourQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetWalkInTourQuery = {
  __typename?: 'Query';
  getModelTourTemplate?: {
    __typename?: 'ModelTourTemplate';
    id?: string | null;
    updatedAt?: any | null;
    metadata?: any | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetAmenityTourTemplateQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetAmenityTourTemplateQuery = {
  __typename?: 'Query';
  getAmenityTourTemplate?: {
    __typename?: 'AmenityTourTemplate';
    id?: string | null;
    updatedAt?: any | null;
    metadata?: any | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type SavePreArrivalMessageMutationVariables = Exact<{
  input: SavePreArrivalMessageInput;
}>;

export type SavePreArrivalMessageMutation = {
  __typename?: 'Mutation';
  savePreArrivalMessage: {
    __typename?: 'SavePreArrivalMessagePayload';
    preArrivalMessage?: { __typename?: 'PreArrivalMessage'; id?: string | null } | null;
  };
};

export type GetPreArrivalMessagesQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;

export type GetPreArrivalMessagesQuery = {
  __typename?: 'Query';
  preArrivalMessages: {
    __typename?: 'PreArrivalMessages';
    data: Array<{
      __typename?: 'PreArrivalMessage';
      id?: string | null;
      communityId?: string | null;
      agentId?: string | null;
      name?: string | null;
      message?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    }>;
  };
};

export type DeletePreArrivalMessageMutationVariables = Exact<{
  input: DeletePreArrivalMessageInput;
}>;

export type DeletePreArrivalMessageMutation = {
  __typename?: 'Mutation';
  deletePreArrivalMessage: {
    __typename?: 'DeletePreArrivalMessagePayload';
    preArrivalMessage?: { __typename?: 'PreArrivalMessage'; id?: string | null } | null;
  };
};

export type RemoveKeylessEntrySkipKastleMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type RemoveKeylessEntrySkipKastleMutation = {
  __typename?: 'Mutation';
  removeKeylessEntrySkipKastle: {
    __typename?: 'KeylessEntry';
    id: string;
    sequence: number;
    tourId: string;
    error?: string | null;
    createdAt: any;
    updatedAt: any;
    apartmentId?: string | null;
    communityId?: string | null;
    prospectEmail?: string | null;
    propertyId: string;
    prospectPhoneNumber?: string | null;
    startTime?: any | null;
    endTime?: any | null;
    deleted?: boolean | null;
  };
};

export type SubmitKeylessEntryMutationVariables = Exact<{
  input: CreateKeylessEntriesInput;
}>;

export type SubmitKeylessEntryMutation = {
  __typename?: 'Mutation';
  createKeylessEntries: {
    __typename?: 'CreateKeylessEntriesPayload';
    isReady: boolean;
    keylessEntries: Array<{
      __typename?: 'KeylessEntry';
      id: string;
      sequence: number;
      error?: string | null;
      apartmentId?: string | null;
      communityId?: string | null;
      propertyId: string;
      startTime?: any | null;
      isBrivo?: boolean | null;
    }>;
  };
};

export type GetKeylessEntriesQueryVariables = Exact<{
  tourId: Scalars['ID'];
}>;

export type GetKeylessEntriesQuery = {
  __typename?: 'Query';
  keylessEntries: {
    __typename?: 'KeylessEntries';
    isReady: boolean;
    keylessEntries: Array<{
      __typename?: 'KeylessEntry';
      id: string;
      error?: string | null;
      apartmentId?: string | null;
      propertyId: string;
      communityId?: string | null;
      startTime?: any | null;
      isBrivo?: boolean | null;
    }>;
  };
};

export type GetKeylessEntriesByApartmentAndPropertyIDsQueryVariables = Exact<{
  input: PropertyApartmentInputs;
}>;

export type GetKeylessEntriesByApartmentAndPropertyIDsQuery = {
  __typename?: 'Query';
  keylessEntriesByPropertyAndApartment?: Array<{
    __typename?: 'KeylessEntry';
    id: string;
    sequence: number;
    error?: string | null;
    createdAt: any;
    updatedAt: any;
    apartmentId?: string | null;
    communityId?: string | null;
    prospectPhoneNumber?: string | null;
    startTime?: any | null;
    endTime?: any | null;
    tourId: string;
    deleted?: boolean | null;
  }> | null;
};

export type CreateTestTourMutationVariables = Exact<{
  input: CreateTestTourInput;
}>;

export type CreateTestTourMutation = {
  __typename?: 'Mutation';
  createTestTour: {
    __typename?: 'CreateTestTourPayload';
    tour?: { __typename?: 'Tour'; tourId?: string | null } | null;
  };
};

export type ExtendTourMutationVariables = Exact<{
  input: ExtendTourInput;
}>;

export type ExtendTourMutation = {
  __typename?: 'Mutation';
  extendTour?: {
    __typename?: 'TourMap';
    id?: string | null;
    extendedAt?: any | null;
    mapData?: {
      __typename?: 'MapData';
      type?: string | null;
      features?: Array<{
        __typename?: 'Feature';
        id?: string | null;
        type?: string | null;
        properties?:
          | {
              __typename: 'MarkerProperties';
              apartmentId?: string | null;
              amenityTitle?: string | null;
              elevatorNumber?: string | null;
              communityId?: string | null;
              communityName?: string | null;
              floorplanUniqueID?: string | null;
              floorplanName?: string | null;
              floor?: number | null;
              onesiteId?: string | null;
              gateNumber?: string | null;
              isModel?: boolean | null;
              isKeyless?: boolean | null;
              isHidden?: boolean | null;
              isOnRoute?: boolean | null;
              opacityIncreased?: boolean | null;
              icon?: string | null;
              iconBase?: string | null;
              markerType?: MarkerType | null;
              notes?: string | null;
              order?: number | null;
              removeFromMPRoute?: boolean | null;
              unitId?: string | null;
              label?: string | null;
              isPinEnabled?: boolean | null;
              autoNotes?: Array<string | null> | null;
              routeStops?: Array<{
                __typename?: 'RouteStop';
                route?: number | null;
                navHelp?: string | null;
                isExit?: boolean | null;
              }> | null;
              mpLocation?: {
                __typename?: 'MPLocation';
                id?: string | null;
                type?: string | null;
                instructions?: Array<string | null> | null;
                geometry?: {
                  __typename?: 'LocationGeometry';
                  bbox?: Array<number | null> | null;
                  coordinates?: string | null;
                  type?: string | null;
                } | null;
                properties?: {
                  __typename?: 'MPProperties';
                  aliases?: Array<string | null> | null;
                  building?: string | null;
                  description?: string | null;
                  externalId?: string | null;
                  fields?: string | null;
                  floor?: string | null;
                  floorName?: string | null;
                  imageURL?: string | null;
                  locationType?: string | null;
                  mapElement?: string | null;
                  name?: string | null;
                  type?: string | null;
                  venue?: string | null;
                  roomId?: string | null;
                  parentId?: string | null;
                  status?: number | null;
                  venueId?: string | null;
                  buildingId?: string | null;
                  anchor?: {
                    __typename?: 'Point';
                    type?: string | null;
                    coordinates?: Array<number | null> | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
          | null;
        geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetFloorPlanImagesQueryVariables = Exact<{
  communityId: Scalars['ID'];
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
}>;

export type GetFloorPlanImagesQuery = {
  __typename?: 'Query';
  community?: {
    __typename?: 'Community';
    id?: string | null;
    floorPlans?: Array<{ __typename?: 'FloorPlans'; floorPlan?: any | null; id?: string | null } | null> | null;
  } | null;
};

export type GetAvailableUnitsByCommunityQueryVariables = Exact<{
  communityId: Scalars['ID'];
  filters: FiltersType;
}>;

export type GetAvailableUnitsByCommunityQuery = {
  __typename?: 'Query';
  availableCommunityApartments?: Array<{
    __typename?: 'Apartment';
    id?: string | null;
    propertyId?: string | null;
    communityId?: string | null;
    buildingNumber?: string | null;
    buildingUnit?: string | null;
    floorPlanName?: string | null;
    floorplanUniqueID?: string | null;
    unitAmenities?: Array<string> | null;
    floorPlanBed?: number | null;
    floorPlanBath?: number | null;
    unitHasDen?: boolean | null;
    unitHasLoft?: boolean | null;
    unitFloor?: number | null;
    floorPlanSqFt?: number | null;
    isModel?: boolean | null;
    isFutureUnit?: boolean | null;
    leaseUp?: boolean | null;
    floorPlan?: any | null;
    unitId?: string | null;
    property?: { __typename?: 'Property'; marketingName?: string | null } | null;
    coordinates?: { __typename?: 'Coordinates'; lat?: number | null; lng?: number | null } | null;
    unitStartingPrice?: {
      __typename?: 'UnitLeasingPrice';
      price?: number | null;
      term?: number | null;
      date?: any | null;
    } | null;
  } | null> | null;
};

export type GetKeylessExceptionsByCommunityQueryVariables = Exact<{
  communityId?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetKeylessExceptionsByCommunityQuery = {
  __typename?: 'Query';
  keylessExceptions: Array<{
    __typename?: 'KeylessException';
    communityId?: string | null;
    apartmentId?: string | null;
    propertyId?: string | null;
    metadata?: any | null;
    createdAt?: any | null;
  }>;
};

export type UpdateCommunityKeylessExceptionsMutationVariables = Exact<{
  input: UpdateExceptionInput;
}>;

export type UpdateCommunityKeylessExceptionsMutation = {
  __typename?: 'Mutation';
  updateKeylessExceptions?: {
    __typename?: 'UpdateExceptionsResponse';
    deletedRows: number;
    exceptions: Array<{
      __typename?: 'KeylessException';
      id?: string | null;
      propertyId?: string | null;
      apartmentId?: string | null;
      metadata?: any | null;
    }>;
  } | null;
};

export type UpdateMapsindoorsTourMapMutationVariables = Exact<{
  input?: InputMaybe<MapsindoorsTourMapInput>;
}>;

export type UpdateMapsindoorsTourMapMutation = {
  __typename?: 'Mutation';
  mapsindoorsTourMap?: {
    __typename?: 'MapsindoorsTourMapReturnType';
    distances?: any | null;
    error?: string | null;
    updatedTourMap?: {
      __typename?: 'TourMap';
      mapData?: {
        __typename?: 'MapData';
        type?: string | null;
        features?: Array<{
          __typename?: 'Feature';
          id?: string | null;
          type?: string | null;
          properties?:
            | {
                __typename: 'MarkerProperties';
                apartmentId?: string | null;
                amenityTitle?: string | null;
                elevatorNumber?: string | null;
                communityId?: string | null;
                communityName?: string | null;
                floorplanUniqueID?: string | null;
                floorplanName?: string | null;
                floor?: number | null;
                onesiteId?: string | null;
                gateNumber?: string | null;
                isModel?: boolean | null;
                isKeyless?: boolean | null;
                isHidden?: boolean | null;
                isOnRoute?: boolean | null;
                opacityIncreased?: boolean | null;
                icon?: string | null;
                iconBase?: string | null;
                markerType?: MarkerType | null;
                notes?: string | null;
                order?: number | null;
                removeFromMPRoute?: boolean | null;
                unitId?: string | null;
                label?: string | null;
                isPinEnabled?: boolean | null;
                autoNotes?: Array<string | null> | null;
                routeStops?: Array<{
                  __typename?: 'RouteStop';
                  route?: number | null;
                  navHelp?: string | null;
                  isExit?: boolean | null;
                }> | null;
                mpLocation?: {
                  __typename?: 'MPLocation';
                  id?: string | null;
                  type?: string | null;
                  instructions?: Array<string | null> | null;
                  geometry?: {
                    __typename?: 'LocationGeometry';
                    bbox?: Array<number | null> | null;
                    coordinates?: string | null;
                    type?: string | null;
                  } | null;
                  properties?: {
                    __typename?: 'MPProperties';
                    aliases?: Array<string | null> | null;
                    building?: string | null;
                    description?: string | null;
                    externalId?: string | null;
                    fields?: string | null;
                    floor?: string | null;
                    floorName?: string | null;
                    imageURL?: string | null;
                    locationType?: string | null;
                    mapElement?: string | null;
                    name?: string | null;
                    type?: string | null;
                    venue?: string | null;
                    roomId?: string | null;
                    parentId?: string | null;
                    status?: number | null;
                    venueId?: string | null;
                    buildingId?: string | null;
                    anchor?: {
                      __typename?: 'Point';
                      type?: string | null;
                      coordinates?: Array<number | null> | null;
                    } | null;
                  } | null;
                } | null;
              }
            | { __typename: 'RouteProperties'; isDriving?: boolean | null; route?: number | null }
            | null;
          geometry?: { __typename?: 'FeatureGeometry'; type?: string | null; coordinates?: any | null } | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export type GetRecentPreArrivalMessageQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;

export type GetRecentPreArrivalMessageQuery = {
  __typename?: 'Query';
  mostRecentPrearrivalMessage?: {
    __typename?: 'PreArrivalMessage';
    id?: string | null;
    communityId?: string | null;
    agentId?: string | null;
    name?: string | null;
    message?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type GetRecentCommunityPasscodeQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;

export type GetRecentCommunityPasscodeQuery = { __typename?: 'Query'; mostRecentCommunityPasscode?: any | null };

export type SaveCommunityPasscodeMutationVariables = Exact<{
  input: SaveCommunityPasscodeInput;
}>;

export type SaveCommunityPasscodeMutation = {
  __typename?: 'Mutation';
  saveCommunityPasscode: {
    __typename?: 'CommunityPasscode';
    id?: string | null;
    communityId?: string | null;
    passcode?: string | null;
    agentId?: string | null;
    createdAt?: any | null;
    enableForPreArrival?: boolean | null;
  };
};

export type GetMostRecentCommunityPasscodeQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;

export type GetMostRecentCommunityPasscodeQuery = { __typename?: 'Query'; mostRecentCommunityPasscode?: any | null };

export type DeleteCommunityPasscodeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCommunityPasscodeMutation = { __typename?: 'Mutation'; deleteCommunityPasscode?: boolean | null };

export const MapDataFieldsFragmentDoc = gql`
  fragment MapDataFields on MapData {
    type
    features {
      id
      type
      properties {
        __typename
        ... on MarkerProperties {
          apartmentId
          amenityTitle
          elevatorNumber
          communityId
          communityName
          floorplanUniqueID
          floorplanName
          floor
          onesiteId
          gateNumber
          isModel
          isKeyless
          isHidden
          isOnRoute
          opacityIncreased
          icon
          iconBase
          markerType
          notes
          order
          routeStops {
            route
            navHelp
            isExit
          }
          mpLocation {
            id
            type
            instructions
            geometry {
              bbox
              coordinates
              type
            }
            properties {
              aliases
              anchor {
                type
                coordinates
              }
              building
              description
              externalId
              fields
              floor
              floorName
              imageURL
              locationType
              mapElement
              name
              type
              venue
              roomId
              parentId
              status
              venueId
              buildingId
            }
          }
          removeFromMPRoute
          unitId
          label
          isPinEnabled
          autoNotes
        }
        ... on RouteProperties {
          isDriving
          route
        }
      }
      geometry {
        type
        coordinates
      }
    }
  }
`;
export const TourStartTimesDocument = gql`
  query TourStartTimes($input: TourStartTimeInput) {
    tourStartTimes(input: $input) {
      id
      tourId
      tour {
        tourId
        startTime
      }
    }
  }
`;

/**
 * __useTourStartTimesQuery__
 *
 * To run a query within a React component, call `useTourStartTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTourStartTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTourStartTimesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTourStartTimesQuery(
  baseOptions?: Apollo.QueryHookOptions<TourStartTimesQuery, TourStartTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TourStartTimesQuery, TourStartTimesQueryVariables>(TourStartTimesDocument, options);
}
export function useTourStartTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TourStartTimesQuery, TourStartTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TourStartTimesQuery, TourStartTimesQueryVariables>(TourStartTimesDocument, options);
}
export type TourStartTimesQueryHookResult = ReturnType<typeof useTourStartTimesQuery>;
export type TourStartTimesLazyQueryHookResult = ReturnType<typeof useTourStartTimesLazyQuery>;
export type TourStartTimesQueryResult = Apollo.QueryResult<TourStartTimesQuery, TourStartTimesQueryVariables>;
export const GetTourMapDocument = gql`
  query GetTourMap($tourId: ID!) {
    tourMap(tourId: $tourId) {
      id
      tourId
      prospectEmail
      prospectNotifiedAt
      propertyId
      isBlocked
      mapData {
        ...MapDataFields
      }
      pivotalData {
        id
        floorplanGroup
        leaseTerm
        occupants
        moveDate
        minRent
        maxRent
        rentDesired
        pets
        vehicles
        floor
        firstName
        lastName
        email
        phone
      }
      tour {
        tourId
        communityId
        communityName
        startTime
        status
        mapDesigner
        preArrivalInstructions
        leasingAgent {
          id
          firstName
          lastName
        }
        leasingEmailAddress
        leasingPhoneNumber
      }
      metadata
      completedAt
      unitInfo {
        property {
          marketingName
        }
        buildingUnit
        pricingAndAvailability {
          leaseTerm
          rentAmount
        }
      }
      unitsVisitedAt
      beganTourAt
      extendedAt
    }
  }
  ${MapDataFieldsFragmentDoc}
`;

/**
 * __useGetTourMapQuery__
 *
 * To run a query within a React component, call `useGetTourMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTourMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTourMapQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetTourMapQuery(baseOptions: Apollo.QueryHookOptions<GetTourMapQuery, GetTourMapQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTourMapQuery, GetTourMapQueryVariables>(GetTourMapDocument, options);
}
export function useGetTourMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTourMapQuery, GetTourMapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTourMapQuery, GetTourMapQueryVariables>(GetTourMapDocument, options);
}
export type GetTourMapQueryHookResult = ReturnType<typeof useGetTourMapQuery>;
export type GetTourMapLazyQueryHookResult = ReturnType<typeof useGetTourMapLazyQuery>;
export type GetTourMapQueryResult = Apollo.QueryResult<GetTourMapQuery, GetTourMapQueryVariables>;
export const GetProspectInfoDocument = gql`
  query GetProspectInfo($tourId: ID!) {
    tourMap(tourId: $tourId) {
      id
      prospectEmail
      propertyId
      createdAt
      completedAt
      metadata
      prospectNotifiedAt
      tour {
        tourId
        communityId
        communityName
        waiverSigned
        startTime
        mapDesigner
        leasingAgent {
          firstName
          lastName
        }
        leasingEmailAddress
        leasingPhoneNumber
      }
    }
  }
`;

/**
 * __useGetProspectInfoQuery__
 *
 * To run a query within a React component, call `useGetProspectInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectInfoQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetProspectInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetProspectInfoQuery, GetProspectInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProspectInfoQuery, GetProspectInfoQueryVariables>(GetProspectInfoDocument, options);
}
export function useGetProspectInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProspectInfoQuery, GetProspectInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProspectInfoQuery, GetProspectInfoQueryVariables>(GetProspectInfoDocument, options);
}
export type GetProspectInfoQueryHookResult = ReturnType<typeof useGetProspectInfoQuery>;
export type GetProspectInfoLazyQueryHookResult = ReturnType<typeof useGetProspectInfoLazyQuery>;
export type GetProspectInfoQueryResult = Apollo.QueryResult<GetProspectInfoQuery, GetProspectInfoQueryVariables>;
export const GetCommunityIdDocument = gql`
  query GetCommunityID($tourId: ID!) {
    tourMap(tourId: $tourId) {
      id
      tour {
        tourId
        communityId
      }
    }
  }
`;

/**
 * __useGetCommunityIdQuery__
 *
 * To run a query within a React component, call `useGetCommunityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityIdQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetCommunityIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommunityIdQuery, GetCommunityIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunityIdQuery, GetCommunityIdQueryVariables>(GetCommunityIdDocument, options);
}
export function useGetCommunityIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityIdQuery, GetCommunityIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommunityIdQuery, GetCommunityIdQueryVariables>(GetCommunityIdDocument, options);
}
export type GetCommunityIdQueryHookResult = ReturnType<typeof useGetCommunityIdQuery>;
export type GetCommunityIdLazyQueryHookResult = ReturnType<typeof useGetCommunityIdLazyQuery>;
export type GetCommunityIdQueryResult = Apollo.QueryResult<GetCommunityIdQuery, GetCommunityIdQueryVariables>;
export const GetCommunityFiltersDocument = gql`
  query GetCommunityFilters($communityId: ID!) {
    community(communityId: $communityId) {
      id
      apartmentsFilter {
        bedrooms
        bathrooms
        floor
        priceRange {
          min
          max
        }
      }
    }
  }
`;

/**
 * __useGetCommunityFiltersQuery__
 *
 * To run a query within a React component, call `useGetCommunityFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityFiltersQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommunityFiltersQuery, GetCommunityFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunityFiltersQuery, GetCommunityFiltersQueryVariables>(
    GetCommunityFiltersDocument,
    options,
  );
}
export function useGetCommunityFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityFiltersQuery, GetCommunityFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommunityFiltersQuery, GetCommunityFiltersQueryVariables>(
    GetCommunityFiltersDocument,
    options,
  );
}
export type GetCommunityFiltersQueryHookResult = ReturnType<typeof useGetCommunityFiltersQuery>;
export type GetCommunityFiltersLazyQueryHookResult = ReturnType<typeof useGetCommunityFiltersLazyQuery>;
export type GetCommunityFiltersQueryResult = Apollo.QueryResult<
  GetCommunityFiltersQuery,
  GetCommunityFiltersQueryVariables
>;
export const GetUnitIDsDocument = gql`
  query GetUnitIDs($communityId: ID!) {
    communityBuildingUnits(communityId: $communityId) {
      units {
        id
        availability
        floorplanName
        floor
        floorplanUniqueID
      }
      marketingName
      communityId
      communityName
      isModel
      id
    }
  }
`;

/**
 * __useGetUnitIDsQuery__
 *
 * To run a query within a React component, call `useGetUnitIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitIDsQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetUnitIDsQuery(baseOptions: Apollo.QueryHookOptions<GetUnitIDsQuery, GetUnitIDsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnitIDsQuery, GetUnitIDsQueryVariables>(GetUnitIDsDocument, options);
}
export function useGetUnitIDsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnitIDsQuery, GetUnitIDsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnitIDsQuery, GetUnitIDsQueryVariables>(GetUnitIDsDocument, options);
}
export type GetUnitIDsQueryHookResult = ReturnType<typeof useGetUnitIDsQuery>;
export type GetUnitIDsLazyQueryHookResult = ReturnType<typeof useGetUnitIDsLazyQuery>;
export type GetUnitIDsQueryResult = Apollo.QueryResult<GetUnitIDsQuery, GetUnitIDsQueryVariables>;
export const ConfirmEmailExistsDocument = gql`
  query ConfirmEmailExists($email: String!) {
    confirmEmail(email: $email) {
      emailVerified
      email
      name
      userId
    }
  }
`;

/**
 * __useConfirmEmailExistsQuery__
 *
 * To run a query within a React component, call `useConfirmEmailExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmEmailExistsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useConfirmEmailExistsQuery(
  baseOptions: Apollo.QueryHookOptions<ConfirmEmailExistsQuery, ConfirmEmailExistsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConfirmEmailExistsQuery, ConfirmEmailExistsQueryVariables>(
    ConfirmEmailExistsDocument,
    options,
  );
}
export function useConfirmEmailExistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConfirmEmailExistsQuery, ConfirmEmailExistsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConfirmEmailExistsQuery, ConfirmEmailExistsQueryVariables>(
    ConfirmEmailExistsDocument,
    options,
  );
}
export type ConfirmEmailExistsQueryHookResult = ReturnType<typeof useConfirmEmailExistsQuery>;
export type ConfirmEmailExistsLazyQueryHookResult = ReturnType<typeof useConfirmEmailExistsLazyQuery>;
export type ConfirmEmailExistsQueryResult = Apollo.QueryResult<
  ConfirmEmailExistsQuery,
  ConfirmEmailExistsQueryVariables
>;
export const PreArrivalNoteAdminsDocument = gql`
  query preArrivalNoteAdmins {
    preArrivalNoteAdmins {
      emails
    }
  }
`;

/**
 * __usePreArrivalNoteAdminsQuery__
 *
 * To run a query within a React component, call `usePreArrivalNoteAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreArrivalNoteAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreArrivalNoteAdminsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreArrivalNoteAdminsQuery(
  baseOptions?: Apollo.QueryHookOptions<PreArrivalNoteAdminsQuery, PreArrivalNoteAdminsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreArrivalNoteAdminsQuery, PreArrivalNoteAdminsQueryVariables>(
    PreArrivalNoteAdminsDocument,
    options,
  );
}
export function usePreArrivalNoteAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PreArrivalNoteAdminsQuery, PreArrivalNoteAdminsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreArrivalNoteAdminsQuery, PreArrivalNoteAdminsQueryVariables>(
    PreArrivalNoteAdminsDocument,
    options,
  );
}
export type PreArrivalNoteAdminsQueryHookResult = ReturnType<typeof usePreArrivalNoteAdminsQuery>;
export type PreArrivalNoteAdminsLazyQueryHookResult = ReturnType<typeof usePreArrivalNoteAdminsLazyQuery>;
export type PreArrivalNoteAdminsQueryResult = Apollo.QueryResult<
  PreArrivalNoteAdminsQuery,
  PreArrivalNoteAdminsQueryVariables
>;
export const BaseMapAdministratorsDocument = gql`
  query baseMapAdministrators {
    baseMapAdministrators {
      emails
    }
  }
`;

/**
 * __useBaseMapAdministratorsQuery__
 *
 * To run a query within a React component, call `useBaseMapAdministratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseMapAdministratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseMapAdministratorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBaseMapAdministratorsQuery(
  baseOptions?: Apollo.QueryHookOptions<BaseMapAdministratorsQuery, BaseMapAdministratorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BaseMapAdministratorsQuery, BaseMapAdministratorsQueryVariables>(
    BaseMapAdministratorsDocument,
    options,
  );
}
export function useBaseMapAdministratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BaseMapAdministratorsQuery, BaseMapAdministratorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BaseMapAdministratorsQuery, BaseMapAdministratorsQueryVariables>(
    BaseMapAdministratorsDocument,
    options,
  );
}
export type BaseMapAdministratorsQueryHookResult = ReturnType<typeof useBaseMapAdministratorsQuery>;
export type BaseMapAdministratorsLazyQueryHookResult = ReturnType<typeof useBaseMapAdministratorsLazyQuery>;
export type BaseMapAdministratorsQueryResult = Apollo.QueryResult<
  BaseMapAdministratorsQuery,
  BaseMapAdministratorsQueryVariables
>;
export const TourMapsByLeasingEmailAddressDocument = gql`
  query TourMapsByLeasingEmailAddress($email: String!, $communityId: String!) {
    tourMapsByLeasingEmailAddress(email: $email, communityId: $communityId) {
      tour {
        tourId
        communityId
        propertyId
        communityName
        startTime
        status
        waiverSigned
        units
        leasingAgent {
          id
          firstName
          lastName
        }
        leasingEmailAddress
        leasingPhoneNumber
        bookingId
        isModel
        preArrivalInstructions
        mapDesigner
        contentfulData {
          zingleNumber
          hasChat
        }
        cancelledAt
        isTest
      }
      agentId
      tourId
      prospectEmail
      propertyId
      mapData {
        ...MapDataFields
      }
      metadata
      pivotalData {
        firstName
        lastName
      }
      createdAt
    }
  }
  ${MapDataFieldsFragmentDoc}
`;

/**
 * __useTourMapsByLeasingEmailAddressQuery__
 *
 * To run a query within a React component, call `useTourMapsByLeasingEmailAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useTourMapsByLeasingEmailAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTourMapsByLeasingEmailAddressQuery({
 *   variables: {
 *      email: // value for 'email'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useTourMapsByLeasingEmailAddressQuery(
  baseOptions: Apollo.QueryHookOptions<TourMapsByLeasingEmailAddressQuery, TourMapsByLeasingEmailAddressQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TourMapsByLeasingEmailAddressQuery, TourMapsByLeasingEmailAddressQueryVariables>(
    TourMapsByLeasingEmailAddressDocument,
    options,
  );
}
export function useTourMapsByLeasingEmailAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TourMapsByLeasingEmailAddressQuery,
    TourMapsByLeasingEmailAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TourMapsByLeasingEmailAddressQuery, TourMapsByLeasingEmailAddressQueryVariables>(
    TourMapsByLeasingEmailAddressDocument,
    options,
  );
}
export type TourMapsByLeasingEmailAddressQueryHookResult = ReturnType<typeof useTourMapsByLeasingEmailAddressQuery>;
export type TourMapsByLeasingEmailAddressLazyQueryHookResult = ReturnType<
  typeof useTourMapsByLeasingEmailAddressLazyQuery
>;
export type TourMapsByLeasingEmailAddressQueryResult = Apollo.QueryResult<
  TourMapsByLeasingEmailAddressQuery,
  TourMapsByLeasingEmailAddressQueryVariables
>;
export const GetProspectDocument = gql`
  query GetProspect($email: EmailAddress!, $startTime: String!, $skipCache: Boolean) {
    getProspectByEmail(email: $email, startTime: $startTime, skipCache: $skipCache) {
      id
      firstName
      lastName
      phone
      email
      idVerificationStatus
      idVerificationDateTime
      idVerification {
        id
        idUsState
        isValid
        verificationStatus
        createdAt
        metadata
        nameCheckFailed
        identityVerification {
          validity
          similarity
          reason
        }
        errorDetails {
          id
          internalTitle
        }
      }
      needs {
        floorplanGroup
        moveDate
        leaseTerm
        occupants
        rentDesired
        pets
        vehicles
        floor
      }
      tours {
        tourId
        communityId
        communityName
        propertyId
        startTime
        isModel
        isAmenity
        status
        waiverSigned
        units
        leasingAgent {
          id
          firstName
          lastName
        }
        leasingEmailAddress
        leasingPhoneNumber
        mapDesigner
        tourMap {
          id
          tourId
          isBlocked
          completedAt
          beganTourAt
          extendedAt
          createdAt
          prospectNotifiedAt
          updatedAt
          cancelledAt
          keylessStatus
          mapData {
            type
          }
          ignoreRestrictions
        }
        isTest
      }
    }
  }
`;

/**
 * __useGetProspectQuery__
 *
 * To run a query within a React component, call `useGetProspectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProspectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProspectQuery({
 *   variables: {
 *      email: // value for 'email'
 *      startTime: // value for 'startTime'
 *      skipCache: // value for 'skipCache'
 *   },
 * });
 */
export function useGetProspectQuery(baseOptions: Apollo.QueryHookOptions<GetProspectQuery, GetProspectQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProspectQuery, GetProspectQueryVariables>(GetProspectDocument, options);
}
export function useGetProspectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProspectQuery, GetProspectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProspectQuery, GetProspectQueryVariables>(GetProspectDocument, options);
}
export type GetProspectQueryHookResult = ReturnType<typeof useGetProspectQuery>;
export type GetProspectLazyQueryHookResult = ReturnType<typeof useGetProspectLazyQuery>;
export type GetProspectQueryResult = Apollo.QueryResult<GetProspectQuery, GetProspectQueryVariables>;
export const GetAppHealthDocument = gql`
  query GetAppHealth {
    appHealth {
      environment
      version
      uptime
      cpu
      memory
    }
  }
`;

/**
 * __useGetAppHealthQuery__
 *
 * To run a query within a React component, call `useGetAppHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppHealthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppHealthQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAppHealthQuery, GetAppHealthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppHealthQuery, GetAppHealthQueryVariables>(GetAppHealthDocument, options);
}
export function useGetAppHealthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppHealthQuery, GetAppHealthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppHealthQuery, GetAppHealthQueryVariables>(GetAppHealthDocument, options);
}
export type GetAppHealthQueryHookResult = ReturnType<typeof useGetAppHealthQuery>;
export type GetAppHealthLazyQueryHookResult = ReturnType<typeof useGetAppHealthLazyQuery>;
export type GetAppHealthQueryResult = Apollo.QueryResult<GetAppHealthQuery, GetAppHealthQueryVariables>;
export const GetAppSqlHealthDocument = gql`
  query GetAppSqlHealth {
    appSqlHealth {
      status
      version
      uptime
      database
      connectionPool
    }
  }
`;

/**
 * __useGetAppSqlHealthQuery__
 *
 * To run a query within a React component, call `useGetAppSqlHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSqlHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSqlHealthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppSqlHealthQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAppSqlHealthQuery, GetAppSqlHealthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppSqlHealthQuery, GetAppSqlHealthQueryVariables>(GetAppSqlHealthDocument, options);
}
export function useGetAppSqlHealthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppSqlHealthQuery, GetAppSqlHealthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppSqlHealthQuery, GetAppSqlHealthQueryVariables>(GetAppSqlHealthDocument, options);
}
export type GetAppSqlHealthQueryHookResult = ReturnType<typeof useGetAppSqlHealthQuery>;
export type GetAppSqlHealthLazyQueryHookResult = ReturnType<typeof useGetAppSqlHealthLazyQuery>;
export type GetAppSqlHealthQueryResult = Apollo.QueryResult<GetAppSqlHealthQuery, GetAppSqlHealthQueryVariables>;
export const GetCloudSqlHealthDocument = gql`
  query GetCloudSqlHealth {
    cloudSqlHealth {
      status
      version
      uptime
      database
      connectionPool
    }
  }
`;

/**
 * __useGetCloudSqlHealthQuery__
 *
 * To run a query within a React component, call `useGetCloudSqlHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudSqlHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudSqlHealthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCloudSqlHealthQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCloudSqlHealthQuery, GetCloudSqlHealthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCloudSqlHealthQuery, GetCloudSqlHealthQueryVariables>(GetCloudSqlHealthDocument, options);
}
export function useGetCloudSqlHealthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCloudSqlHealthQuery, GetCloudSqlHealthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCloudSqlHealthQuery, GetCloudSqlHealthQueryVariables>(
    GetCloudSqlHealthDocument,
    options,
  );
}
export type GetCloudSqlHealthQueryHookResult = ReturnType<typeof useGetCloudSqlHealthQuery>;
export type GetCloudSqlHealthLazyQueryHookResult = ReturnType<typeof useGetCloudSqlHealthLazyQuery>;
export type GetCloudSqlHealthQueryResult = Apollo.QueryResult<GetCloudSqlHealthQuery, GetCloudSqlHealthQueryVariables>;
export const GetRedisHealthDocument = gql`
  query GetRedisHealth {
    redisHealth {
      status
      version
      uptime
      cpu
      memory
      databases
      keySpace
    }
  }
`;

/**
 * __useGetRedisHealthQuery__
 *
 * To run a query within a React component, call `useGetRedisHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedisHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedisHealthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRedisHealthQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRedisHealthQuery, GetRedisHealthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRedisHealthQuery, GetRedisHealthQueryVariables>(GetRedisHealthDocument, options);
}
export function useGetRedisHealthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRedisHealthQuery, GetRedisHealthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRedisHealthQuery, GetRedisHealthQueryVariables>(GetRedisHealthDocument, options);
}
export type GetRedisHealthQueryHookResult = ReturnType<typeof useGetRedisHealthQuery>;
export type GetRedisHealthLazyQueryHookResult = ReturnType<typeof useGetRedisHealthLazyQuery>;
export type GetRedisHealthQueryResult = Apollo.QueryResult<GetRedisHealthQuery, GetRedisHealthQueryVariables>;
export const FlushDataCacheDocument = gql`
  mutation FlushDataCache {
    flushDataCache
  }
`;
export type FlushDataCacheMutationFn = Apollo.MutationFunction<FlushDataCacheMutation, FlushDataCacheMutationVariables>;

/**
 * __useFlushDataCacheMutation__
 *
 * To run a mutation, you first call `useFlushDataCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlushDataCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flushDataCacheMutation, { data, loading, error }] = useFlushDataCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useFlushDataCacheMutation(
  baseOptions?: Apollo.MutationHookOptions<FlushDataCacheMutation, FlushDataCacheMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FlushDataCacheMutation, FlushDataCacheMutationVariables>(FlushDataCacheDocument, options);
}
export type FlushDataCacheMutationHookResult = ReturnType<typeof useFlushDataCacheMutation>;
export type FlushDataCacheMutationResult = Apollo.MutationResult<FlushDataCacheMutation>;
export type FlushDataCacheMutationOptions = Apollo.BaseMutationOptions<
  FlushDataCacheMutation,
  FlushDataCacheMutationVariables
>;
export const FlushAllCacheDocument = gql`
  mutation FlushAllCache {
    flushAllCache
  }
`;
export type FlushAllCacheMutationFn = Apollo.MutationFunction<FlushAllCacheMutation, FlushAllCacheMutationVariables>;

/**
 * __useFlushAllCacheMutation__
 *
 * To run a mutation, you first call `useFlushAllCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlushAllCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flushAllCacheMutation, { data, loading, error }] = useFlushAllCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useFlushAllCacheMutation(
  baseOptions?: Apollo.MutationHookOptions<FlushAllCacheMutation, FlushAllCacheMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FlushAllCacheMutation, FlushAllCacheMutationVariables>(FlushAllCacheDocument, options);
}
export type FlushAllCacheMutationHookResult = ReturnType<typeof useFlushAllCacheMutation>;
export type FlushAllCacheMutationResult = Apollo.MutationResult<FlushAllCacheMutation>;
export type FlushAllCacheMutationOptions = Apollo.BaseMutationOptions<
  FlushAllCacheMutation,
  FlushAllCacheMutationVariables
>;
export const SaveTourMapDocument = gql`
  mutation SaveTourMap($input: SaveTourMapInput!) {
    saveTourMap(input: $input) {
      id
      metadata
    }
  }
`;
export type SaveTourMapMutationFn = Apollo.MutationFunction<SaveTourMapMutation, SaveTourMapMutationVariables>;

/**
 * __useSaveTourMapMutation__
 *
 * To run a mutation, you first call `useSaveTourMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTourMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTourMapMutation, { data, loading, error }] = useSaveTourMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTourMapMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveTourMapMutation, SaveTourMapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveTourMapMutation, SaveTourMapMutationVariables>(SaveTourMapDocument, options);
}
export type SaveTourMapMutationHookResult = ReturnType<typeof useSaveTourMapMutation>;
export type SaveTourMapMutationResult = Apollo.MutationResult<SaveTourMapMutation>;
export type SaveTourMapMutationOptions = Apollo.BaseMutationOptions<SaveTourMapMutation, SaveTourMapMutationVariables>;
export const UpdateTourMapDocument = gql`
  mutation UpdateTourMap($input: UpdateTourMapInput!) {
    updateTourMap(input: $input) {
      id
      metadata
    }
  }
`;
export type UpdateTourMapMutationFn = Apollo.MutationFunction<UpdateTourMapMutation, UpdateTourMapMutationVariables>;

/**
 * __useUpdateTourMapMutation__
 *
 * To run a mutation, you first call `useUpdateTourMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTourMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTourMapMutation, { data, loading, error }] = useUpdateTourMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTourMapMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTourMapMutation, UpdateTourMapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTourMapMutation, UpdateTourMapMutationVariables>(UpdateTourMapDocument, options);
}
export type UpdateTourMapMutationHookResult = ReturnType<typeof useUpdateTourMapMutation>;
export type UpdateTourMapMutationResult = Apollo.MutationResult<UpdateTourMapMutation>;
export type UpdateTourMapMutationOptions = Apollo.BaseMutationOptions<
  UpdateTourMapMutation,
  UpdateTourMapMutationVariables
>;
export const BlockMapDocument = gql`
  mutation BlockMap($input: BlockMapInput!) {
    blockMap(input: $input) {
      id
    }
  }
`;
export type BlockMapMutationFn = Apollo.MutationFunction<BlockMapMutation, BlockMapMutationVariables>;

/**
 * __useBlockMapMutation__
 *
 * To run a mutation, you first call `useBlockMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockMapMutation, { data, loading, error }] = useBlockMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockMapMutation(
  baseOptions?: Apollo.MutationHookOptions<BlockMapMutation, BlockMapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlockMapMutation, BlockMapMutationVariables>(BlockMapDocument, options);
}
export type BlockMapMutationHookResult = ReturnType<typeof useBlockMapMutation>;
export type BlockMapMutationResult = Apollo.MutationResult<BlockMapMutation>;
export type BlockMapMutationOptions = Apollo.BaseMutationOptions<BlockMapMutation, BlockMapMutationVariables>;
export const UnNotifyTourMapAndRemoveKeylessEntriesDocument = gql`
  mutation UnNotifyTourMapAndRemoveKeylessEntries($input: UnNotifyTourAndRemoveKeylessEntriesInput!) {
    unNotifyTourAndRemoveKeylessEntries(input: $input) {
      allRemoved
      keylessEntries {
        id
        sequence
        tourId
        error
        createdAt
        updatedAt
        apartmentId
        communityId
        prospectEmail
        propertyId
        prospectPhoneNumber
        startTime
        deleted
      }
    }
  }
`;
export type UnNotifyTourMapAndRemoveKeylessEntriesMutationFn = Apollo.MutationFunction<
  UnNotifyTourMapAndRemoveKeylessEntriesMutation,
  UnNotifyTourMapAndRemoveKeylessEntriesMutationVariables
>;

/**
 * __useUnNotifyTourMapAndRemoveKeylessEntriesMutation__
 *
 * To run a mutation, you first call `useUnNotifyTourMapAndRemoveKeylessEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnNotifyTourMapAndRemoveKeylessEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unNotifyTourMapAndRemoveKeylessEntriesMutation, { data, loading, error }] = useUnNotifyTourMapAndRemoveKeylessEntriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnNotifyTourMapAndRemoveKeylessEntriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnNotifyTourMapAndRemoveKeylessEntriesMutation,
    UnNotifyTourMapAndRemoveKeylessEntriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnNotifyTourMapAndRemoveKeylessEntriesMutation,
    UnNotifyTourMapAndRemoveKeylessEntriesMutationVariables
  >(UnNotifyTourMapAndRemoveKeylessEntriesDocument, options);
}
export type UnNotifyTourMapAndRemoveKeylessEntriesMutationHookResult = ReturnType<
  typeof useUnNotifyTourMapAndRemoveKeylessEntriesMutation
>;
export type UnNotifyTourMapAndRemoveKeylessEntriesMutationResult =
  Apollo.MutationResult<UnNotifyTourMapAndRemoveKeylessEntriesMutation>;
export type UnNotifyTourMapAndRemoveKeylessEntriesMutationOptions = Apollo.BaseMutationOptions<
  UnNotifyTourMapAndRemoveKeylessEntriesMutation,
  UnNotifyTourMapAndRemoveKeylessEntriesMutationVariables
>;
export const DeleteTourMapDocument = gql`
  mutation DeleteTourMap($input: DeleteTourMapInput!) {
    deleteTourMap(input: $input) {
      id
      mapData {
        ...MapDataFields
      }
    }
  }
  ${MapDataFieldsFragmentDoc}
`;
export type DeleteTourMapMutationFn = Apollo.MutationFunction<DeleteTourMapMutation, DeleteTourMapMutationVariables>;

/**
 * __useDeleteTourMapMutation__
 *
 * To run a mutation, you first call `useDeleteTourMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTourMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTourMapMutation, { data, loading, error }] = useDeleteTourMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTourMapMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTourMapMutation, DeleteTourMapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTourMapMutation, DeleteTourMapMutationVariables>(DeleteTourMapDocument, options);
}
export type DeleteTourMapMutationHookResult = ReturnType<typeof useDeleteTourMapMutation>;
export type DeleteTourMapMutationResult = Apollo.MutationResult<DeleteTourMapMutation>;
export type DeleteTourMapMutationOptions = Apollo.BaseMutationOptions<
  DeleteTourMapMutation,
  DeleteTourMapMutationVariables
>;
export const SaveBaseTemplateDocument = gql`
  mutation SaveBaseTemplate($input: SaveBaseTemplateInput!) {
    saveBaseTemplate(input: $input) {
      id
      mapData {
        ...MapDataFields
      }
    }
  }
  ${MapDataFieldsFragmentDoc}
`;
export type SaveBaseTemplateMutationFn = Apollo.MutationFunction<
  SaveBaseTemplateMutation,
  SaveBaseTemplateMutationVariables
>;

/**
 * __useSaveBaseTemplateMutation__
 *
 * To run a mutation, you first call `useSaveBaseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBaseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBaseTemplateMutation, { data, loading, error }] = useSaveBaseTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBaseTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveBaseTemplateMutation, SaveBaseTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveBaseTemplateMutation, SaveBaseTemplateMutationVariables>(
    SaveBaseTemplateDocument,
    options,
  );
}
export type SaveBaseTemplateMutationHookResult = ReturnType<typeof useSaveBaseTemplateMutation>;
export type SaveBaseTemplateMutationResult = Apollo.MutationResult<SaveBaseTemplateMutation>;
export type SaveBaseTemplateMutationOptions = Apollo.BaseMutationOptions<
  SaveBaseTemplateMutation,
  SaveBaseTemplateMutationVariables
>;
export const SaveModelTourTemplateDocument = gql`
  mutation SaveModelTourTemplate($input: SaveModelTourTemplateInput!) {
    saveModelTourTemplate(input: $input) {
      id
      mapData {
        ...MapDataFields
      }
    }
  }
  ${MapDataFieldsFragmentDoc}
`;
export type SaveModelTourTemplateMutationFn = Apollo.MutationFunction<
  SaveModelTourTemplateMutation,
  SaveModelTourTemplateMutationVariables
>;

/**
 * __useSaveModelTourTemplateMutation__
 *
 * To run a mutation, you first call `useSaveModelTourTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveModelTourTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveModelTourTemplateMutation, { data, loading, error }] = useSaveModelTourTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveModelTourTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveModelTourTemplateMutation, SaveModelTourTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveModelTourTemplateMutation, SaveModelTourTemplateMutationVariables>(
    SaveModelTourTemplateDocument,
    options,
  );
}
export type SaveModelTourTemplateMutationHookResult = ReturnType<typeof useSaveModelTourTemplateMutation>;
export type SaveModelTourTemplateMutationResult = Apollo.MutationResult<SaveModelTourTemplateMutation>;
export type SaveModelTourTemplateMutationOptions = Apollo.BaseMutationOptions<
  SaveModelTourTemplateMutation,
  SaveModelTourTemplateMutationVariables
>;
export const SaveAmenityTourTemplateDocument = gql`
  mutation SaveAmenityTourTemplate($input: SaveAmenityTourTemplateInput!) {
    saveAmenityTourTemplate(input: $input) {
      id
      mapData {
        ...MapDataFields
      }
    }
  }
  ${MapDataFieldsFragmentDoc}
`;
export type SaveAmenityTourTemplateMutationFn = Apollo.MutationFunction<
  SaveAmenityTourTemplateMutation,
  SaveAmenityTourTemplateMutationVariables
>;

/**
 * __useSaveAmenityTourTemplateMutation__
 *
 * To run a mutation, you first call `useSaveAmenityTourTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAmenityTourTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAmenityTourTemplateMutation, { data, loading, error }] = useSaveAmenityTourTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAmenityTourTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveAmenityTourTemplateMutation, SaveAmenityTourTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveAmenityTourTemplateMutation, SaveAmenityTourTemplateMutationVariables>(
    SaveAmenityTourTemplateDocument,
    options,
  );
}
export type SaveAmenityTourTemplateMutationHookResult = ReturnType<typeof useSaveAmenityTourTemplateMutation>;
export type SaveAmenityTourTemplateMutationResult = Apollo.MutationResult<SaveAmenityTourTemplateMutation>;
export type SaveAmenityTourTemplateMutationOptions = Apollo.BaseMutationOptions<
  SaveAmenityTourTemplateMutation,
  SaveAmenityTourTemplateMutationVariables
>;
export const SetTourMapReadyDocument = gql`
  mutation SetTourMapReady($input: SetTourMapReadyInput!) {
    setTourMapReady(input: $input) {
      id
    }
  }
`;
export type SetTourMapReadyMutationFn = Apollo.MutationFunction<
  SetTourMapReadyMutation,
  SetTourMapReadyMutationVariables
>;

/**
 * __useSetTourMapReadyMutation__
 *
 * To run a mutation, you first call `useSetTourMapReadyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTourMapReadyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTourMapReadyMutation, { data, loading, error }] = useSetTourMapReadyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTourMapReadyMutation(
  baseOptions?: Apollo.MutationHookOptions<SetTourMapReadyMutation, SetTourMapReadyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetTourMapReadyMutation, SetTourMapReadyMutationVariables>(
    SetTourMapReadyDocument,
    options,
  );
}
export type SetTourMapReadyMutationHookResult = ReturnType<typeof useSetTourMapReadyMutation>;
export type SetTourMapReadyMutationResult = Apollo.MutationResult<SetTourMapReadyMutation>;
export type SetTourMapReadyMutationOptions = Apollo.BaseMutationOptions<
  SetTourMapReadyMutation,
  SetTourMapReadyMutationVariables
>;
export const GetTileDocument = gql`
  query GetTile($communityId: ID!) {
    communityTile(communityId: $communityId) {
      communityName
      mapBoxId
      bounds
      bearing
      isKeyless
      hasAutoMarkerPlacement
      hideFloors
      enablePreArrivalInstructionLockDown
      requiredAmenities
      mapsIndoorsVenueId
      mapsPeopleEnabled
      shouldShowMapsPeopleExperiencePopUp
      unitKeylessProvider
      amenityKeylessProvider
      maximumTravelDistance
      mapsIndoorsApi
      preArrivalInstructions
    }
  }
`;

/**
 * __useGetTileQuery__
 *
 * To run a query within a React component, call `useGetTileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTileQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetTileQuery(baseOptions: Apollo.QueryHookOptions<GetTileQuery, GetTileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTileQuery, GetTileQueryVariables>(GetTileDocument, options);
}
export function useGetTileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTileQuery, GetTileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTileQuery, GetTileQueryVariables>(GetTileDocument, options);
}
export type GetTileQueryHookResult = ReturnType<typeof useGetTileQuery>;
export type GetTileLazyQueryHookResult = ReturnType<typeof useGetTileLazyQuery>;
export type GetTileQueryResult = Apollo.QueryResult<GetTileQuery, GetTileQueryVariables>;
export const GetBaseTemplateDocument = gql`
  query GetBaseTemplate($communityId: ID!) {
    baseTemplate(communityId: $communityId) {
      id
      updatedAt
      mapData {
        ...MapDataFields
      }
      metadata
    }
  }
  ${MapDataFieldsFragmentDoc}
`;

/**
 * __useGetBaseTemplateQuery__
 *
 * To run a query within a React component, call `useGetBaseTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseTemplateQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetBaseTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<GetBaseTemplateQuery, GetBaseTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBaseTemplateQuery, GetBaseTemplateQueryVariables>(GetBaseTemplateDocument, options);
}
export function useGetBaseTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBaseTemplateQuery, GetBaseTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBaseTemplateQuery, GetBaseTemplateQueryVariables>(GetBaseTemplateDocument, options);
}
export type GetBaseTemplateQueryHookResult = ReturnType<typeof useGetBaseTemplateQuery>;
export type GetBaseTemplateLazyQueryHookResult = ReturnType<typeof useGetBaseTemplateLazyQuery>;
export type GetBaseTemplateQueryResult = Apollo.QueryResult<GetBaseTemplateQuery, GetBaseTemplateQueryVariables>;
export const GetToursByPropertyDocument = gql`
  query GetToursByProperty($communityId: ID!) {
    tourMaps(communityId: $communityId) {
      user {
        id
        isBlocked
        metadata
        tourId
        createdAt
        mapData {
          ...MapDataFields
        }
        pivotalData {
          id
          floorplanGroup
          moveDate
          firstName
          lastName
          email
          phone
        }
        tour {
          tourId
          startTime
          mapDesigner
        }
      }
      community {
        id
        isBlocked
        metadata
        tourId
        createdAt
        mapData {
          ...MapDataFields
        }
        pivotalData {
          id
          floorplanGroup
          moveDate
          firstName
          lastName
          email
          phone
        }
        tour {
          tourId
          startTime
          mapDesigner
        }
      }
    }
  }
  ${MapDataFieldsFragmentDoc}
`;

/**
 * __useGetToursByPropertyQuery__
 *
 * To run a query within a React component, call `useGetToursByPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToursByPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToursByPropertyQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetToursByPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<GetToursByPropertyQuery, GetToursByPropertyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetToursByPropertyQuery, GetToursByPropertyQueryVariables>(
    GetToursByPropertyDocument,
    options,
  );
}
export function useGetToursByPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetToursByPropertyQuery, GetToursByPropertyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetToursByPropertyQuery, GetToursByPropertyQueryVariables>(
    GetToursByPropertyDocument,
    options,
  );
}
export type GetToursByPropertyQueryHookResult = ReturnType<typeof useGetToursByPropertyQuery>;
export type GetToursByPropertyLazyQueryHookResult = ReturnType<typeof useGetToursByPropertyLazyQuery>;
export type GetToursByPropertyQueryResult = Apollo.QueryResult<
  GetToursByPropertyQuery,
  GetToursByPropertyQueryVariables
>;
export const GetContentfulCommunitiesDocument = gql`
  query GetContentfulCommunities($query: String!, $isModel: Boolean) {
    contentfulCommunities(query: $query) {
      communityId
      enablePreArrivalInstructionLockDown
      communityName
      mapsPeopleEnabled
      lastEdited(isModel: $isModel)
      community {
        properties {
          id
          marketingName
        }
      }
    }
  }
`;

/**
 * __useGetContentfulCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetContentfulCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentfulCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentfulCommunitiesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      isModel: // value for 'isModel'
 *   },
 * });
 */
export function useGetContentfulCommunitiesQuery(
  baseOptions: Apollo.QueryHookOptions<GetContentfulCommunitiesQuery, GetContentfulCommunitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentfulCommunitiesQuery, GetContentfulCommunitiesQueryVariables>(
    GetContentfulCommunitiesDocument,
    options,
  );
}
export function useGetContentfulCommunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContentfulCommunitiesQuery, GetContentfulCommunitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContentfulCommunitiesQuery, GetContentfulCommunitiesQueryVariables>(
    GetContentfulCommunitiesDocument,
    options,
  );
}
export type GetContentfulCommunitiesQueryHookResult = ReturnType<typeof useGetContentfulCommunitiesQuery>;
export type GetContentfulCommunitiesLazyQueryHookResult = ReturnType<typeof useGetContentfulCommunitiesLazyQuery>;
export type GetContentfulCommunitiesQueryResult = Apollo.QueryResult<
  GetContentfulCommunitiesQuery,
  GetContentfulCommunitiesQueryVariables
>;
export const GetWalkInTourDocument = gql`
  query GetWalkInTour($communityId: ID!) {
    getModelTourTemplate(communityId: $communityId) {
      id
      updatedAt
      mapData {
        ...MapDataFields
      }
      metadata
    }
  }
  ${MapDataFieldsFragmentDoc}
`;

/**
 * __useGetWalkInTourQuery__
 *
 * To run a query within a React component, call `useGetWalkInTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalkInTourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalkInTourQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetWalkInTourQuery(
  baseOptions: Apollo.QueryHookOptions<GetWalkInTourQuery, GetWalkInTourQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWalkInTourQuery, GetWalkInTourQueryVariables>(GetWalkInTourDocument, options);
}
export function useGetWalkInTourLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWalkInTourQuery, GetWalkInTourQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWalkInTourQuery, GetWalkInTourQueryVariables>(GetWalkInTourDocument, options);
}
export type GetWalkInTourQueryHookResult = ReturnType<typeof useGetWalkInTourQuery>;
export type GetWalkInTourLazyQueryHookResult = ReturnType<typeof useGetWalkInTourLazyQuery>;
export type GetWalkInTourQueryResult = Apollo.QueryResult<GetWalkInTourQuery, GetWalkInTourQueryVariables>;
export const GetAmenityTourTemplateDocument = gql`
  query GetAmenityTourTemplate($communityId: ID!) {
    getAmenityTourTemplate(communityId: $communityId) {
      id
      updatedAt
      mapData {
        ...MapDataFields
      }
      metadata
    }
  }
  ${MapDataFieldsFragmentDoc}
`;

/**
 * __useGetAmenityTourTemplateQuery__
 *
 * To run a query within a React component, call `useGetAmenityTourTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAmenityTourTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAmenityTourTemplateQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetAmenityTourTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<GetAmenityTourTemplateQuery, GetAmenityTourTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAmenityTourTemplateQuery, GetAmenityTourTemplateQueryVariables>(
    GetAmenityTourTemplateDocument,
    options,
  );
}
export function useGetAmenityTourTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAmenityTourTemplateQuery, GetAmenityTourTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAmenityTourTemplateQuery, GetAmenityTourTemplateQueryVariables>(
    GetAmenityTourTemplateDocument,
    options,
  );
}
export type GetAmenityTourTemplateQueryHookResult = ReturnType<typeof useGetAmenityTourTemplateQuery>;
export type GetAmenityTourTemplateLazyQueryHookResult = ReturnType<typeof useGetAmenityTourTemplateLazyQuery>;
export type GetAmenityTourTemplateQueryResult = Apollo.QueryResult<
  GetAmenityTourTemplateQuery,
  GetAmenityTourTemplateQueryVariables
>;
export const SavePreArrivalMessageDocument = gql`
  mutation SavePreArrivalMessage($input: SavePreArrivalMessageInput!) {
    savePreArrivalMessage(input: $input) {
      preArrivalMessage {
        id
      }
    }
  }
`;
export type SavePreArrivalMessageMutationFn = Apollo.MutationFunction<
  SavePreArrivalMessageMutation,
  SavePreArrivalMessageMutationVariables
>;

/**
 * __useSavePreArrivalMessageMutation__
 *
 * To run a mutation, you first call `useSavePreArrivalMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePreArrivalMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePreArrivalMessageMutation, { data, loading, error }] = useSavePreArrivalMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePreArrivalMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<SavePreArrivalMessageMutation, SavePreArrivalMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SavePreArrivalMessageMutation, SavePreArrivalMessageMutationVariables>(
    SavePreArrivalMessageDocument,
    options,
  );
}
export type SavePreArrivalMessageMutationHookResult = ReturnType<typeof useSavePreArrivalMessageMutation>;
export type SavePreArrivalMessageMutationResult = Apollo.MutationResult<SavePreArrivalMessageMutation>;
export type SavePreArrivalMessageMutationOptions = Apollo.BaseMutationOptions<
  SavePreArrivalMessageMutation,
  SavePreArrivalMessageMutationVariables
>;
export const GetPreArrivalMessagesDocument = gql`
  query GetPreArrivalMessages($communityId: String!) {
    preArrivalMessages(communityId: $communityId) {
      data {
        id
        communityId
        agentId
        name
        message
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetPreArrivalMessagesQuery__
 *
 * To run a query within a React component, call `useGetPreArrivalMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreArrivalMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreArrivalMessagesQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetPreArrivalMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPreArrivalMessagesQuery, GetPreArrivalMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPreArrivalMessagesQuery, GetPreArrivalMessagesQueryVariables>(
    GetPreArrivalMessagesDocument,
    options,
  );
}
export function useGetPreArrivalMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPreArrivalMessagesQuery, GetPreArrivalMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPreArrivalMessagesQuery, GetPreArrivalMessagesQueryVariables>(
    GetPreArrivalMessagesDocument,
    options,
  );
}
export type GetPreArrivalMessagesQueryHookResult = ReturnType<typeof useGetPreArrivalMessagesQuery>;
export type GetPreArrivalMessagesLazyQueryHookResult = ReturnType<typeof useGetPreArrivalMessagesLazyQuery>;
export type GetPreArrivalMessagesQueryResult = Apollo.QueryResult<
  GetPreArrivalMessagesQuery,
  GetPreArrivalMessagesQueryVariables
>;
export const DeletePreArrivalMessageDocument = gql`
  mutation DeletePreArrivalMessage($input: DeletePreArrivalMessageInput!) {
    deletePreArrivalMessage(input: $input) {
      preArrivalMessage {
        id
      }
    }
  }
`;
export type DeletePreArrivalMessageMutationFn = Apollo.MutationFunction<
  DeletePreArrivalMessageMutation,
  DeletePreArrivalMessageMutationVariables
>;

/**
 * __useDeletePreArrivalMessageMutation__
 *
 * To run a mutation, you first call `useDeletePreArrivalMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreArrivalMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreArrivalMessageMutation, { data, loading, error }] = useDeletePreArrivalMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePreArrivalMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePreArrivalMessageMutation, DeletePreArrivalMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePreArrivalMessageMutation, DeletePreArrivalMessageMutationVariables>(
    DeletePreArrivalMessageDocument,
    options,
  );
}
export type DeletePreArrivalMessageMutationHookResult = ReturnType<typeof useDeletePreArrivalMessageMutation>;
export type DeletePreArrivalMessageMutationResult = Apollo.MutationResult<DeletePreArrivalMessageMutation>;
export type DeletePreArrivalMessageMutationOptions = Apollo.BaseMutationOptions<
  DeletePreArrivalMessageMutation,
  DeletePreArrivalMessageMutationVariables
>;
export const RemoveKeylessEntrySkipKastleDocument = gql`
  mutation RemoveKeylessEntrySkipKastle($input: String!) {
    removeKeylessEntrySkipKastle(input: $input) {
      id
      sequence
      tourId
      error
      createdAt
      updatedAt
      apartmentId
      communityId
      prospectEmail
      propertyId
      prospectPhoneNumber
      startTime
      endTime
      deleted
    }
  }
`;
export type RemoveKeylessEntrySkipKastleMutationFn = Apollo.MutationFunction<
  RemoveKeylessEntrySkipKastleMutation,
  RemoveKeylessEntrySkipKastleMutationVariables
>;

/**
 * __useRemoveKeylessEntrySkipKastleMutation__
 *
 * To run a mutation, you first call `useRemoveKeylessEntrySkipKastleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeylessEntrySkipKastleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeylessEntrySkipKastleMutation, { data, loading, error }] = useRemoveKeylessEntrySkipKastleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveKeylessEntrySkipKastleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveKeylessEntrySkipKastleMutation,
    RemoveKeylessEntrySkipKastleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveKeylessEntrySkipKastleMutation, RemoveKeylessEntrySkipKastleMutationVariables>(
    RemoveKeylessEntrySkipKastleDocument,
    options,
  );
}
export type RemoveKeylessEntrySkipKastleMutationHookResult = ReturnType<typeof useRemoveKeylessEntrySkipKastleMutation>;
export type RemoveKeylessEntrySkipKastleMutationResult = Apollo.MutationResult<RemoveKeylessEntrySkipKastleMutation>;
export type RemoveKeylessEntrySkipKastleMutationOptions = Apollo.BaseMutationOptions<
  RemoveKeylessEntrySkipKastleMutation,
  RemoveKeylessEntrySkipKastleMutationVariables
>;
export const SubmitKeylessEntryDocument = gql`
  mutation SubmitKeylessEntry($input: CreateKeylessEntriesInput!) {
    createKeylessEntries(input: $input) {
      isReady
      keylessEntries {
        id
        sequence
        error
        apartmentId
        communityId
        propertyId
        startTime
        isBrivo
      }
    }
  }
`;
export type SubmitKeylessEntryMutationFn = Apollo.MutationFunction<
  SubmitKeylessEntryMutation,
  SubmitKeylessEntryMutationVariables
>;

/**
 * __useSubmitKeylessEntryMutation__
 *
 * To run a mutation, you first call `useSubmitKeylessEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitKeylessEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitKeylessEntryMutation, { data, loading, error }] = useSubmitKeylessEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitKeylessEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitKeylessEntryMutation, SubmitKeylessEntryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitKeylessEntryMutation, SubmitKeylessEntryMutationVariables>(
    SubmitKeylessEntryDocument,
    options,
  );
}
export type SubmitKeylessEntryMutationHookResult = ReturnType<typeof useSubmitKeylessEntryMutation>;
export type SubmitKeylessEntryMutationResult = Apollo.MutationResult<SubmitKeylessEntryMutation>;
export type SubmitKeylessEntryMutationOptions = Apollo.BaseMutationOptions<
  SubmitKeylessEntryMutation,
  SubmitKeylessEntryMutationVariables
>;
export const GetKeylessEntriesDocument = gql`
  query GetKeylessEntries($tourId: ID!) {
    keylessEntries(tourId: $tourId) {
      isReady
      keylessEntries {
        id
        error
        apartmentId
        propertyId
        communityId
        startTime
        isBrivo
      }
    }
  }
`;

/**
 * __useGetKeylessEntriesQuery__
 *
 * To run a query within a React component, call `useGetKeylessEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeylessEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeylessEntriesQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetKeylessEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<GetKeylessEntriesQuery, GetKeylessEntriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetKeylessEntriesQuery, GetKeylessEntriesQueryVariables>(GetKeylessEntriesDocument, options);
}
export function useGetKeylessEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetKeylessEntriesQuery, GetKeylessEntriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetKeylessEntriesQuery, GetKeylessEntriesQueryVariables>(
    GetKeylessEntriesDocument,
    options,
  );
}
export type GetKeylessEntriesQueryHookResult = ReturnType<typeof useGetKeylessEntriesQuery>;
export type GetKeylessEntriesLazyQueryHookResult = ReturnType<typeof useGetKeylessEntriesLazyQuery>;
export type GetKeylessEntriesQueryResult = Apollo.QueryResult<GetKeylessEntriesQuery, GetKeylessEntriesQueryVariables>;
export const GetKeylessEntriesByApartmentAndPropertyIDsDocument = gql`
  query GetKeylessEntriesByApartmentAndPropertyIDs($input: PropertyApartmentInputs!) {
    keylessEntriesByPropertyAndApartment(input: $input) {
      id
      sequence
      error
      createdAt
      updatedAt
      apartmentId
      communityId
      prospectPhoneNumber
      startTime
      endTime
      tourId
      deleted
    }
  }
`;

/**
 * __useGetKeylessEntriesByApartmentAndPropertyIDsQuery__
 *
 * To run a query within a React component, call `useGetKeylessEntriesByApartmentAndPropertyIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeylessEntriesByApartmentAndPropertyIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeylessEntriesByApartmentAndPropertyIDsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetKeylessEntriesByApartmentAndPropertyIDsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKeylessEntriesByApartmentAndPropertyIDsQuery,
    GetKeylessEntriesByApartmentAndPropertyIDsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetKeylessEntriesByApartmentAndPropertyIDsQuery,
    GetKeylessEntriesByApartmentAndPropertyIDsQueryVariables
  >(GetKeylessEntriesByApartmentAndPropertyIDsDocument, options);
}
export function useGetKeylessEntriesByApartmentAndPropertyIDsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKeylessEntriesByApartmentAndPropertyIDsQuery,
    GetKeylessEntriesByApartmentAndPropertyIDsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetKeylessEntriesByApartmentAndPropertyIDsQuery,
    GetKeylessEntriesByApartmentAndPropertyIDsQueryVariables
  >(GetKeylessEntriesByApartmentAndPropertyIDsDocument, options);
}
export type GetKeylessEntriesByApartmentAndPropertyIDsQueryHookResult = ReturnType<
  typeof useGetKeylessEntriesByApartmentAndPropertyIDsQuery
>;
export type GetKeylessEntriesByApartmentAndPropertyIDsLazyQueryHookResult = ReturnType<
  typeof useGetKeylessEntriesByApartmentAndPropertyIDsLazyQuery
>;
export type GetKeylessEntriesByApartmentAndPropertyIDsQueryResult = Apollo.QueryResult<
  GetKeylessEntriesByApartmentAndPropertyIDsQuery,
  GetKeylessEntriesByApartmentAndPropertyIDsQueryVariables
>;
export const CreateTestTourDocument = gql`
  mutation CreateTestTour($input: CreateTestTourInput!) {
    createTestTour(input: $input) {
      tour {
        tourId
      }
    }
  }
`;
export type CreateTestTourMutationFn = Apollo.MutationFunction<CreateTestTourMutation, CreateTestTourMutationVariables>;

/**
 * __useCreateTestTourMutation__
 *
 * To run a mutation, you first call `useCreateTestTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestTourMutation, { data, loading, error }] = useCreateTestTourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTestTourMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTestTourMutation, CreateTestTourMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTestTourMutation, CreateTestTourMutationVariables>(CreateTestTourDocument, options);
}
export type CreateTestTourMutationHookResult = ReturnType<typeof useCreateTestTourMutation>;
export type CreateTestTourMutationResult = Apollo.MutationResult<CreateTestTourMutation>;
export type CreateTestTourMutationOptions = Apollo.BaseMutationOptions<
  CreateTestTourMutation,
  CreateTestTourMutationVariables
>;
export const ExtendTourDocument = gql`
  mutation ExtendTour($input: ExtendTourInput!) {
    extendTour(input: $input) {
      id
      extendedAt
      mapData {
        ...MapDataFields
      }
    }
  }
  ${MapDataFieldsFragmentDoc}
`;
export type ExtendTourMutationFn = Apollo.MutationFunction<ExtendTourMutation, ExtendTourMutationVariables>;

/**
 * __useExtendTourMutation__
 *
 * To run a mutation, you first call `useExtendTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendTourMutation, { data, loading, error }] = useExtendTourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtendTourMutation(
  baseOptions?: Apollo.MutationHookOptions<ExtendTourMutation, ExtendTourMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExtendTourMutation, ExtendTourMutationVariables>(ExtendTourDocument, options);
}
export type ExtendTourMutationHookResult = ReturnType<typeof useExtendTourMutation>;
export type ExtendTourMutationResult = Apollo.MutationResult<ExtendTourMutation>;
export type ExtendTourMutationOptions = Apollo.BaseMutationOptions<ExtendTourMutation, ExtendTourMutationVariables>;
export const GetFloorPlanImagesDocument = gql`
  query GetFloorPlanImages($communityId: ID!, $width: Int, $height: Int) {
    community(communityId: $communityId) {
      floorPlans(width: $width, height: $height) {
        floorPlan
        id
      }
      id
    }
  }
`;

/**
 * __useGetFloorPlanImagesQuery__
 *
 * To run a query within a React component, call `useGetFloorPlanImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFloorPlanImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFloorPlanImagesQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useGetFloorPlanImagesQuery(
  baseOptions: Apollo.QueryHookOptions<GetFloorPlanImagesQuery, GetFloorPlanImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFloorPlanImagesQuery, GetFloorPlanImagesQueryVariables>(
    GetFloorPlanImagesDocument,
    options,
  );
}
export function useGetFloorPlanImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFloorPlanImagesQuery, GetFloorPlanImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFloorPlanImagesQuery, GetFloorPlanImagesQueryVariables>(
    GetFloorPlanImagesDocument,
    options,
  );
}
export type GetFloorPlanImagesQueryHookResult = ReturnType<typeof useGetFloorPlanImagesQuery>;
export type GetFloorPlanImagesLazyQueryHookResult = ReturnType<typeof useGetFloorPlanImagesLazyQuery>;
export type GetFloorPlanImagesQueryResult = Apollo.QueryResult<
  GetFloorPlanImagesQuery,
  GetFloorPlanImagesQueryVariables
>;
export const GetAvailableUnitsByCommunityDocument = gql`
  query getAvailableUnitsByCommunity($communityId: ID!, $filters: FiltersType!) {
    availableCommunityApartments(communityId: $communityId, filters: $filters) {
      id
      propertyId
      property {
        marketingName
      }
      communityId
      buildingNumber
      buildingUnit
      property {
        marketingName
      }
      floorPlanName
      coordinates {
        lat
        lng
      }
      floorplanUniqueID
      unitAmenities
      floorPlanBed
      floorPlanBath
      unitHasDen
      unitHasLoft
      unitFloor
      floorPlanSqFt
      unitStartingPrice {
        price
        term
        date
      }
      isModel
      isFutureUnit
      leaseUp
      floorPlan
      unitId
    }
  }
`;

/**
 * __useGetAvailableUnitsByCommunityQuery__
 *
 * To run a query within a React component, call `useGetAvailableUnitsByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableUnitsByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableUnitsByCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAvailableUnitsByCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<GetAvailableUnitsByCommunityQuery, GetAvailableUnitsByCommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAvailableUnitsByCommunityQuery, GetAvailableUnitsByCommunityQueryVariables>(
    GetAvailableUnitsByCommunityDocument,
    options,
  );
}
export function useGetAvailableUnitsByCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableUnitsByCommunityQuery,
    GetAvailableUnitsByCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAvailableUnitsByCommunityQuery, GetAvailableUnitsByCommunityQueryVariables>(
    GetAvailableUnitsByCommunityDocument,
    options,
  );
}
export type GetAvailableUnitsByCommunityQueryHookResult = ReturnType<typeof useGetAvailableUnitsByCommunityQuery>;
export type GetAvailableUnitsByCommunityLazyQueryHookResult = ReturnType<
  typeof useGetAvailableUnitsByCommunityLazyQuery
>;
export type GetAvailableUnitsByCommunityQueryResult = Apollo.QueryResult<
  GetAvailableUnitsByCommunityQuery,
  GetAvailableUnitsByCommunityQueryVariables
>;
export const GetKeylessExceptionsByCommunityDocument = gql`
  query getKeylessExceptionsByCommunity($communityId: [String!]) {
    keylessExceptions(communityId: $communityId) {
      communityId
      apartmentId
      propertyId
      metadata
      createdAt
    }
  }
`;

/**
 * __useGetKeylessExceptionsByCommunityQuery__
 *
 * To run a query within a React component, call `useGetKeylessExceptionsByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeylessExceptionsByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeylessExceptionsByCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetKeylessExceptionsByCommunityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetKeylessExceptionsByCommunityQuery,
    GetKeylessExceptionsByCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetKeylessExceptionsByCommunityQuery, GetKeylessExceptionsByCommunityQueryVariables>(
    GetKeylessExceptionsByCommunityDocument,
    options,
  );
}
export function useGetKeylessExceptionsByCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKeylessExceptionsByCommunityQuery,
    GetKeylessExceptionsByCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetKeylessExceptionsByCommunityQuery, GetKeylessExceptionsByCommunityQueryVariables>(
    GetKeylessExceptionsByCommunityDocument,
    options,
  );
}
export type GetKeylessExceptionsByCommunityQueryHookResult = ReturnType<typeof useGetKeylessExceptionsByCommunityQuery>;
export type GetKeylessExceptionsByCommunityLazyQueryHookResult = ReturnType<
  typeof useGetKeylessExceptionsByCommunityLazyQuery
>;
export type GetKeylessExceptionsByCommunityQueryResult = Apollo.QueryResult<
  GetKeylessExceptionsByCommunityQuery,
  GetKeylessExceptionsByCommunityQueryVariables
>;
export const UpdateCommunityKeylessExceptionsDocument = gql`
  mutation updateCommunityKeylessExceptions($input: UpdateExceptionInput!) {
    updateKeylessExceptions(input: $input) {
      deletedRows
      exceptions {
        id
        propertyId
        apartmentId
        metadata
      }
    }
  }
`;
export type UpdateCommunityKeylessExceptionsMutationFn = Apollo.MutationFunction<
  UpdateCommunityKeylessExceptionsMutation,
  UpdateCommunityKeylessExceptionsMutationVariables
>;

/**
 * __useUpdateCommunityKeylessExceptionsMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityKeylessExceptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityKeylessExceptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityKeylessExceptionsMutation, { data, loading, error }] = useUpdateCommunityKeylessExceptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityKeylessExceptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommunityKeylessExceptionsMutation,
    UpdateCommunityKeylessExceptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCommunityKeylessExceptionsMutation,
    UpdateCommunityKeylessExceptionsMutationVariables
  >(UpdateCommunityKeylessExceptionsDocument, options);
}
export type UpdateCommunityKeylessExceptionsMutationHookResult = ReturnType<
  typeof useUpdateCommunityKeylessExceptionsMutation
>;
export type UpdateCommunityKeylessExceptionsMutationResult =
  Apollo.MutationResult<UpdateCommunityKeylessExceptionsMutation>;
export type UpdateCommunityKeylessExceptionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommunityKeylessExceptionsMutation,
  UpdateCommunityKeylessExceptionsMutationVariables
>;
export const UpdateMapsindoorsTourMapDocument = gql`
  mutation updateMapsindoorsTourMap($input: MapsindoorsTourMapInput) {
    mapsindoorsTourMap(input: $input) {
      updatedTourMap {
        mapData {
          ...MapDataFields
        }
      }
      distances
      error
    }
  }
  ${MapDataFieldsFragmentDoc}
`;
export type UpdateMapsindoorsTourMapMutationFn = Apollo.MutationFunction<
  UpdateMapsindoorsTourMapMutation,
  UpdateMapsindoorsTourMapMutationVariables
>;

/**
 * __useUpdateMapsindoorsTourMapMutation__
 *
 * To run a mutation, you first call `useUpdateMapsindoorsTourMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMapsindoorsTourMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMapsindoorsTourMapMutation, { data, loading, error }] = useUpdateMapsindoorsTourMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMapsindoorsTourMapMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMapsindoorsTourMapMutation, UpdateMapsindoorsTourMapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMapsindoorsTourMapMutation, UpdateMapsindoorsTourMapMutationVariables>(
    UpdateMapsindoorsTourMapDocument,
    options,
  );
}
export type UpdateMapsindoorsTourMapMutationHookResult = ReturnType<typeof useUpdateMapsindoorsTourMapMutation>;
export type UpdateMapsindoorsTourMapMutationResult = Apollo.MutationResult<UpdateMapsindoorsTourMapMutation>;
export type UpdateMapsindoorsTourMapMutationOptions = Apollo.BaseMutationOptions<
  UpdateMapsindoorsTourMapMutation,
  UpdateMapsindoorsTourMapMutationVariables
>;
export const GetRecentPreArrivalMessageDocument = gql`
  query GetRecentPreArrivalMessage($communityId: String!) {
    mostRecentPrearrivalMessage(communityId: $communityId) {
      id
      communityId
      agentId
      name
      message
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetRecentPreArrivalMessageQuery__
 *
 * To run a query within a React component, call `useGetRecentPreArrivalMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentPreArrivalMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentPreArrivalMessageQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetRecentPreArrivalMessageQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecentPreArrivalMessageQuery, GetRecentPreArrivalMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecentPreArrivalMessageQuery, GetRecentPreArrivalMessageQueryVariables>(
    GetRecentPreArrivalMessageDocument,
    options,
  );
}
export function useGetRecentPreArrivalMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecentPreArrivalMessageQuery, GetRecentPreArrivalMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecentPreArrivalMessageQuery, GetRecentPreArrivalMessageQueryVariables>(
    GetRecentPreArrivalMessageDocument,
    options,
  );
}
export type GetRecentPreArrivalMessageQueryHookResult = ReturnType<typeof useGetRecentPreArrivalMessageQuery>;
export type GetRecentPreArrivalMessageLazyQueryHookResult = ReturnType<typeof useGetRecentPreArrivalMessageLazyQuery>;
export type GetRecentPreArrivalMessageQueryResult = Apollo.QueryResult<
  GetRecentPreArrivalMessageQuery,
  GetRecentPreArrivalMessageQueryVariables
>;
export const GetRecentCommunityPasscodeDocument = gql`
  query GetRecentCommunityPasscode($communityId: String!) {
    mostRecentCommunityPasscode(communityId: $communityId)
  }
`;

/**
 * __useGetRecentCommunityPasscodeQuery__
 *
 * To run a query within a React component, call `useGetRecentCommunityPasscodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentCommunityPasscodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentCommunityPasscodeQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetRecentCommunityPasscodeQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecentCommunityPasscodeQuery, GetRecentCommunityPasscodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecentCommunityPasscodeQuery, GetRecentCommunityPasscodeQueryVariables>(
    GetRecentCommunityPasscodeDocument,
    options,
  );
}
export function useGetRecentCommunityPasscodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecentCommunityPasscodeQuery, GetRecentCommunityPasscodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecentCommunityPasscodeQuery, GetRecentCommunityPasscodeQueryVariables>(
    GetRecentCommunityPasscodeDocument,
    options,
  );
}
export type GetRecentCommunityPasscodeQueryHookResult = ReturnType<typeof useGetRecentCommunityPasscodeQuery>;
export type GetRecentCommunityPasscodeLazyQueryHookResult = ReturnType<typeof useGetRecentCommunityPasscodeLazyQuery>;
export type GetRecentCommunityPasscodeQueryResult = Apollo.QueryResult<
  GetRecentCommunityPasscodeQuery,
  GetRecentCommunityPasscodeQueryVariables
>;
export const SaveCommunityPasscodeDocument = gql`
  mutation SaveCommunityPasscode($input: SaveCommunityPasscodeInput!) {
    saveCommunityPasscode(input: $input) {
      id
      communityId
      passcode
      agentId
      createdAt
      enableForPreArrival
    }
  }
`;
export type SaveCommunityPasscodeMutationFn = Apollo.MutationFunction<
  SaveCommunityPasscodeMutation,
  SaveCommunityPasscodeMutationVariables
>;

/**
 * __useSaveCommunityPasscodeMutation__
 *
 * To run a mutation, you first call `useSaveCommunityPasscodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCommunityPasscodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCommunityPasscodeMutation, { data, loading, error }] = useSaveCommunityPasscodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCommunityPasscodeMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveCommunityPasscodeMutation, SaveCommunityPasscodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveCommunityPasscodeMutation, SaveCommunityPasscodeMutationVariables>(
    SaveCommunityPasscodeDocument,
    options,
  );
}
export type SaveCommunityPasscodeMutationHookResult = ReturnType<typeof useSaveCommunityPasscodeMutation>;
export type SaveCommunityPasscodeMutationResult = Apollo.MutationResult<SaveCommunityPasscodeMutation>;
export type SaveCommunityPasscodeMutationOptions = Apollo.BaseMutationOptions<
  SaveCommunityPasscodeMutation,
  SaveCommunityPasscodeMutationVariables
>;
export const GetMostRecentCommunityPasscodeDocument = gql`
  query GetMostRecentCommunityPasscode($communityId: String!) {
    mostRecentCommunityPasscode(communityId: $communityId)
  }
`;

/**
 * __useGetMostRecentCommunityPasscodeQuery__
 *
 * To run a query within a React component, call `useGetMostRecentCommunityPasscodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentCommunityPasscodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentCommunityPasscodeQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetMostRecentCommunityPasscodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMostRecentCommunityPasscodeQuery,
    GetMostRecentCommunityPasscodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMostRecentCommunityPasscodeQuery, GetMostRecentCommunityPasscodeQueryVariables>(
    GetMostRecentCommunityPasscodeDocument,
    options,
  );
}
export function useGetMostRecentCommunityPasscodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostRecentCommunityPasscodeQuery,
    GetMostRecentCommunityPasscodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMostRecentCommunityPasscodeQuery, GetMostRecentCommunityPasscodeQueryVariables>(
    GetMostRecentCommunityPasscodeDocument,
    options,
  );
}
export type GetMostRecentCommunityPasscodeQueryHookResult = ReturnType<typeof useGetMostRecentCommunityPasscodeQuery>;
export type GetMostRecentCommunityPasscodeLazyQueryHookResult = ReturnType<
  typeof useGetMostRecentCommunityPasscodeLazyQuery
>;
export type GetMostRecentCommunityPasscodeQueryResult = Apollo.QueryResult<
  GetMostRecentCommunityPasscodeQuery,
  GetMostRecentCommunityPasscodeQueryVariables
>;
export const DeleteCommunityPasscodeDocument = gql`
  mutation DeleteCommunityPasscode($id: ID!) {
    deleteCommunityPasscode(id: $id)
  }
`;
export type DeleteCommunityPasscodeMutationFn = Apollo.MutationFunction<
  DeleteCommunityPasscodeMutation,
  DeleteCommunityPasscodeMutationVariables
>;

/**
 * __useDeleteCommunityPasscodeMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityPasscodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityPasscodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityPasscodeMutation, { data, loading, error }] = useDeleteCommunityPasscodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommunityPasscodeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCommunityPasscodeMutation, DeleteCommunityPasscodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCommunityPasscodeMutation, DeleteCommunityPasscodeMutationVariables>(
    DeleteCommunityPasscodeDocument,
    options,
  );
}
export type DeleteCommunityPasscodeMutationHookResult = ReturnType<typeof useDeleteCommunityPasscodeMutation>;
export type DeleteCommunityPasscodeMutationResult = Apollo.MutationResult<DeleteCommunityPasscodeMutation>;
export type DeleteCommunityPasscodeMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommunityPasscodeMutation,
  DeleteCommunityPasscodeMutationVariables
>;
