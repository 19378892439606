import { BoxProps } from '@chakra-ui/core';
type TextProps = Omit<BoxProps, 'as' | 'size' | 'width' | 'height' | 'defaultValue'>;
export const h1: TextProps = {
  fontFamily: 'Miller Display',
  fontWeight: 800,
  fontSize: '28px',
  color: 'primaryDark',
};
export const headerLarge: TextProps = {
  fontFamily: 'Miller Display',
  fontWeight: 800,
  fontSize: '24px',
  color: 'primaryDark',
};

export const h1Alt: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '42px',
  letterSpacing: '-0.5px',
  color: 'primaryDark',
};

export const h2: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: '18px',
  color: 'primaryDark',
};

export const h3: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '18px',
  color: 'primaryDark',
};

export const h4: TextProps = {
  fontFamily: 'Miller Display',
  fontSize: '14px',
  fontWeight: 300,
  color: 'primaryDark',
};

export const h5: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '13px',
  color: 'primaryDark',
  lineHeight: '24px',
  textTransform: 'capitalize',
};

export const h6: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '13px',
  color: 'darkGray',
  lineHeight: '24px',
  textTransform: 'capitalize',
};

export const bodyLarge: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 300,
  fontSize: '13px',
  color: 'primaryDark',
  lineHeight: '22px',
};

export const bodyLargeBold: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '14px',
  color: 'primaryDark',
  lineHeight: '24px',
  textAlign: 'center',
};

export const bodyLargeGray: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 325,
  fontSize: '14px',
  color: 'mediumGray',
  lineHeight: '24px',
  textAlign: 'center',
};

export const darkBodyLarge: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 350,
  fontSize: '13px',
  color: 'primaryDark',
};

export const bodyMedium: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 300,
  fontSize: '12px',
  color: 'primaryDark',
  lineHeight: '16px',
};

export const linkHeader: TextProps = {
  fontFamily: 'Montserrat',
  fontSize: '17px',
  lineHeight: '42px',
  letterSpacing: '-0.5425px',
};

export const boldBodyHeader: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
};

export const bodySmall: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: '10px',
  color: 'primaryDark',
  lineHeight: '13px',
};

export const boldSmall: TextProps = {
  borderRadius: '8px',
  fontFamily: 'Montserrat',
  color: '#1B2A33',
  fontStyle: 'normal',
  fontSize: '17px',
  lineHeight: '42px',
  fontWeight: 400,
};

export const smallCaps: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '24px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
};
export const headerSmall: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '24px',
  letterSpacing: '1.5px',
};
export const errorSmall: TextProps = {
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '12px',
  color: 'alertRed',
  lineHeight: '13px',
};
